export const Profile = (color, width, { style }) => {
  const symbolStyle = style;
  const [baseWidth, baseHeight] = [21, 21];
  const symbolWidth = width || baseWidth;
  const symbolHeight = symbolWidth / (baseWidth / baseHeight);
  const symbolColor = color;
  return {
    viewBox: "0 0 21 21",
    height: symbolHeight,
    width: symbolWidth,
    fillRule: "nonzero",
    style: symbolStyle,
    color: symbolColor,
    paths: [
      "M10.5 0C4.71 0 0 4.71 0 10.5S4.71 21 10.5 21 21 16.29 21 10.5 16.29 0 10.5 0zM10 5a3 3 0 110 6 3 3 0 010-6zm7 12.771C15.352 19.165 13.266 20 10.997 20c-2.266 0-4.35-.832-5.997-2.223C5.153 14.903 6.941 12 9.129 12h3.74c2.192 0 3.985 2.798 4.13 5.676l.001.001v.094z",
    ],
  };
};

export const Close = (color, width, { style }) => {
  const symbolColor = color;
  const symbolStyle = style;
  const [baseWidth, baseHeight] = [300, 300];
  const symbolWidth = width || baseWidth;
  const symbolHeight = symbolWidth / (baseWidth / baseHeight);
  return {
    viewBox: "0 0 300 300",
    height: symbolHeight,
    width: symbolWidth,
    style: symbolStyle,
    color: symbolColor,
    paths: [
      "M150 0c82.843 0 150 67.157 150 150s-67.157 150-150 150S0 232.843 0 150 67.157 0 150 0zm0 14C74.89 14 14 74.89 14 150s60.89 136 136 136 136-60.89 136-136S225.11 14 150 14zm49.212 79.146l9.642 9.642L160.642 151l48.212 48.212-9.642 9.642L151 160.642l-48.212 48.212-9.642-9.642L141.358 151l-48.212-48.212 9.642-9.642L151 141.358l48.212-48.212z",
    ],
    fillRule: "evenodd",
  };
};
