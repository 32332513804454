const state = {
    newContainer: {},
    newVehicle: {},
    newBox: {},
};

const getters = {
    getNewContainer: (state) => state.newContainer,
    getNewVehicle: (state) => state.newVehicle,
    getNewBox: (state) => state.newBox,
};

const actions = {
    setNewContainer({ commit }, container) {
        commit("mutateNewContainer", container)
    },
    setNewVehicle({ commit }, vehicle) {
        commit("mutateNewVehicle", vehicle)
    },
    setNewBox({ commit }, box) {
        commit("mutateNewBox", box)
    },
};

const mutations = {
    mutateNewContainer: (state, newContainer) => (state.newContainer = newContainer),
    mutateNewVehicle: (state, newVehicle) => (state.newVehicle = newVehicle),
    mutateNewBox: (state, newBox) => (state.newBox = newBox),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
