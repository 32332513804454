<template>
  <div>
    <AddUnitManually
      class="detailCard"
      v-if="isShowingManualWindow"
      @cancel="isShowingManualWindow = false"
      @success="closeCamera"
    />

    <Camera
      v-if="isShowingUnitCamera"
      v-on:scanned="saveUnitCode"
      itemThatShouldBeScanned="unit"
      :invalidCode="unitDoesNotExist"
    >
      <Button
        label="Skip"
        @clicked="closeCamera"
      />
      <Button
        :label="$t('swapQrCode.typeManually')"
        @clicked="isShowingManualWindow = true"
      />
    </Camera>

    <Camera
      v-if="isShowingContainerCamera == true"
      v-on:scanned="saveContainerCode"
      itemThatShouldBeScanned="container"
      :invalidCode="containerWrongFormat"
      :showCheckMark="showCheck"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Camera from "../../components/camera/CameraComponent";
import AddUnitManually from "../../components/popups/AddUnitManually.vue";
import { getContainer, getUnit } from "../../services/reenapi";
import {
  isValidTriplogCode,
  isValidUnitCode,
  isValidQrCode,
} from "../../utils/validators";

export default {
  components: {
    Camera,
    AddUnitManually,
  },
  data() {
    return {
      isShowingUnitCamera: true,
      isShowingContainerCamera: false,
      containerWrongFormat: false,
      unitDoesNotExist: false,
      isShowingManualWindow: false,
      showCheck: false,
    };
  },
  methods: {
    ...mapActions([
      "setScannedQrContainer",
      "setScannedExistingContainer",
      "setScannedExistingUnit",
      "setScannedExistingTriplog",
    ]),

    async saveUnitCode(id) {
      if (id === "") return;

      if (isValidTriplogCode(id)) {
        let response = await getUnit(id);

        if (response.status === 200 && response.data.length > 0) {
          this.unitDoesNotExist = false;
          this.setScannedExistingTriplog(response.data[0]);
          this.$router.push("registernewvehicle");
        } else {
          this.unitDoesNotExist = true;
        }
      } else if (isValidUnitCode(id)) {
        let response = await getUnit(id);

        if (response.status === 200 && response.data.length > 0) {
          this.unitDoesNotExist = false;
          this.setScannedExistingUnit(response.data[0]);
          this.isShowingUnitCamera = false;
          this.isShowingContainerCamera = true;
          this.showCheck = true;

          let vue = this;
          setTimeout(() => {
            vue.showCheck = false;
          }, 1000);
        } else if (response.status === 404) {
          this.unitDoesNotExist = true;
        }
      } else {
        this.unitDoesNotExist = true;
        this.$forceUpdate();
      }
    },

    closeCamera() {
      this.isShowingManualWindow = false;
      this.isShowingUnitCamera = false;
      this.isShowingContainerCamera = true;
    },

    async saveContainerCode(qr) {
      if (qr === "") return;

      if (!isValidQrCode(qr)) {
        this.containerWrongFormat = true;
        return;
      }
      let response = await getContainer(qr);

      if (response.status === 200 && response.data.length > 0) {
        this.unitDoesNotExist = false;
        this.containerWrongFormat = false;
        this.setScannedExistingContainer(response.data[0]);
        this.$router.push("swapdevice");
      } else {
        this.setScannedQrContainer(qr);
        this.$router.push("registernewcontainer");
      }
      this.isShowingContainerCamera = false;
    },
  },
};
</script>

<style scoped>
.overlay-field {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.errorMessage {
  background-color: #ff6a6a;
  display: inline;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 1.3em;
  color: black;
}
.detailCard {
  z-index: 1000;
}
</style>
