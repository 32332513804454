<template>
  <div>
    <div class="inputStyle">
      <div class="headline">
        {{ label }}:
        <div class="headlineRight">
          <BIconAsterisk
            v-if="showAsterisk"
            font-scale="0.8"
            style="margin-top: 2px; margin-right: 5px; color: #ffe893"
          />
          <b-icon-question-circle
            v-b-tooltip.click.blur.right.v-light="tooltip"
          />
        </div>
      </div>

      <!-- Text -->
      <b-form-input
        v-if="type === 'text'"
        :type="type"
        :value="value"
        @input="$emit('inputValue', $event)"
        :disabled="isDisabled"
      />

      <!-- Password -->
      <b-form-input
        v-if="type === 'password'"
        :type="type"
        :value="value"
        @input="$emit('inputValue', $event)"
      />

      <!-- Number -->
      <b-form-input
        v-if="type === 'number'"
        :type="type"
        :value="value"
        @input="$emit('inputValue', $event)"
      />

      <!-- Number, but text-->
      <b-form-input
          v-if="type === 'text-number'"
          type="text"
          :value="value"
          @input="$emit('inputValue', ($event === '') ? undefined : Number($event))"
      />

      <!-- Select -->
      <b-form-select
        v-if="type === 'select'"
        :value="checkArrayLength"
        @change="$emit('inputValue', $event)"
        :options="optionValues"
        :disabled="isDisabled"
      >
        <template #first v-if="placeholder">
          <b-form-select-option :value="undefined" disabled>
            {{ placeholder }}
          </b-form-select-option>
        </template>
      </b-form-select>

      <!-- Date -->
      <DatePicker
        v-if="type === 'date'"
        @change="$emit('inputValue', $event)"
        :showAsterisk="showAsterisk"
       />

      <!-- Radio -->
      <!-- Er kun brukt i ContainerTypeConfig - og det skal vi ikke ha? -->
      <div class="inputRadioStyle" v-if="type === 'yesno'">
        <b-form-radio
          :value="true"
          v-model="radioSelected"
          @input="$emit('inputValue', $event)"
        >
          Ja
        </b-form-radio>
        <b-form-radio
          :value="false"
          v-model="radioSelected"
          @input="$emit('inputValue', $event)"
        >
          Nei
        </b-form-radio>
      </div>

      <div class="errorTextMessage" v-if="isRequired">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { BIconAsterisk } from "bootstrap-vue";
import DatePicker from "../inputs/DatePickerComponent.vue";

export default {
  components: {
    BIconAsterisk,
    DatePicker
  },
  props: {
    placeholder: String,
    label: String,
    tooltip: String,
    isRequired: Boolean,
    errorMessage: {
      type: String,
      default: "Dette feltet kreves.",
    },
    isDisabled: Boolean,
    showAsterisk: Boolean,
    type: {
      type: String,
      validator: (formType) => {
        if (
          formType === "text" ||
          formType === "password" ||
          formType === "number" ||
          formType === "text-number" ||
          formType === "select" ||
          formType === "yesno" ||
          formType === "date"
        ) {
          return true;
        } else {
          return false;
        }
      },
    },
    value: {
      type: [String, Number, Object],
      default: undefined,
    },
    optionValues: Array,
  },
  data() {
    return {
      radioSelected: null,
    };
  },
  computed: {
    checkArrayLength() {
      if (this.value == undefined) return;
      let valueToBeShowed = this.optionValues.length === 1 ? this.optionValues[0].value : this.value;
      this.$emit('inputValue', valueToBeShowed)
      return valueToBeShowed;
    }
  },
  methods: {
    handleNumber(event) {
      return (event === "") ? undefined : Number(event);
    }
  }
};
</script>

<style scoped>
.bi-question-circle {
  cursor: pointer;
}

.inputStyle {
  padding: 10px;
}

.headline {
  display: flex;
  justify-content: space-between;
  width: 224px;
  margin: auto;
  font-weight: bold;
  letter-spacing: 0.1rem;
}

.headlineRight {
  display: flex;
  width: 35px;
  justify-content: flex-end;
}

.errorTextMessage {
  color: #dc3545;
  font-size: 16px;
  text-align: center;
  margin-top: 5px;
}

.inputRadioStyle {
  padding-top: 10px;
  width: 224px;
  display: flex;
  justify-content: space-evenly;
  margin: auto;
}
:disabled {
  --disabled-color: grey;
  border-color: var(--disabled-color);
  color: var(--disabled-color) !important;
}
</style>