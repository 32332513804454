<template>
  <div>
    <img class="logo" src="../../assets/LogoWhite.png" alt="logo" />
    <h1>ONBOARDING</h1>
    <div id="btn" @click="loginWithRedirect()">
      <span>{{ $t("login") }}</span>
      <Icon class="icon" :data="profileIcon" />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icons/Icons.vue";
import { Profile } from "../icons/icons";

export default {
  name: "Login",
  components: {
    Icon,
  },
  data() {
    return {
      profileIcon: Profile("black", 25, {}),
    };
  },
  methods: {
    loginWithRedirect() {
      this.$auth.loginWithRedirect();
    },
  },
};
</script>

<style scoped>
.logo {
  margin-top: 100px;
  background-color: transparent;
}

#btn {
  background-color: #f7f8f8;
  color: rgb(0, 0, 0);
  width: 150px;
  margin: auto;
  font-size: 1.125em;
  cursor: pointer;
  border-radius: 25px;
  padding: 13px 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  padding-left: 18px;
}
</style>