<template>
  <div>
    <b-form @submit="validateForm">
      <div v-for="(data, index) in formData" :key="index">

      <Input
        :type="data.type"
        :label="data.label"
        :tooltip="data.tooltip"
        :isRequired="errorArray[index]"
        :placeholder="data.placeholder"
        :optionValues="data.options"
        v-on:inputValue="validateForm(data.name, $event, index)"
        :value="data.value"
        :showAsterisk="data.asterisk"
      />
      </div>
      <b-button type="submit">Submit</b-button>
    </b-form>
    <!-- <div v-for="(data, index) in formData" :key="index">
      <Input
        :type="data.type"
        :label="data.label"
        :tooltip="data.tooltip"
        :isRequired="errorArray[index]"
        :placeholder="data.placeholder"
        :optionValues="data.options"
        v-on:inputValue="validateForm(data.name, $event, index)"
        :value="data.value"
        :showAsterisk="data.asterisk"
      />
    </div>
    <button @click="validateForm">Check</button> -->
  </div>
</template>

<script>
export default {
  props: {
    formData: Array,
  },
  data() {
    return {
      getError: null,
      isError: null,
      chosenValues: {},
      errorArray: [],
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log("SUBMIT");
      console.log(event);
    },
    validateForm(name, value, index) {
      let isErrorHere =
        value === undefined && this.formData[index].isRequired === true
          ? true
          : false;
      this.errorArray[index] = isErrorHere;
      this.chosenValues[name] = value;

      console.log(this.errorArray);
      console.log(this.chosenValues);
      console.log(value);
      console.log(index);
    },
  },
};
</script>

<style>
</style>