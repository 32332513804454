export function isValidTriplogCode(code) {
    return (
        code.length === 9 && (code.startsWith("MUS") || code.startsWith("ANS"))
    );
}

export function isValidUnitCode(code) {
    return (
        (code.length === 9 && code.startsWith("VOL"))
        || (code.length === 8 && code.startsWith("AM"))
        || (code.length === 9 && code.startsWith("MUG"))
    );
}

export function isValidQrCode(qr) {
    return qr.length === 6;
}

export function isValidBoxCode(code) {
    return code.length === 10 && code.startsWith("BX");
}