<template>
  <div class="background">
    <b-container>
      <div>
        <h1>{{ $t("registerNewVehicle.title") }}</h1>

        <Input
          type="text"
          :label="$t('registerNewVehicle.serial')"
          :tooltip="$t('registerNewVehicle.serialDesc')"
          :isDisabled="true"
          :value="getScannedExistingTriplog.serial_number"
        />

        <Input
          type="text"
          :label="$t('registerNewVehicle.licensePlate')"
          :tooltip="$t('registerNewVehicle.licensePlateDesc')"
          :value="licensePlate"
          v-on:inputValue="licensePlate = $event.toUpperCase()"
          :showAsterisk="true"
          :isDisabled="finishLicensePlate"
        />
        <b-button class="vehicleBtn" @click="getVehicleInfo"
          >{{ $t("registerNewVehicle.getInfo") }}
        </b-button>

        <b-row>
          <b-col v-if="showErrorLicensePlate">
            <div class="mainErrorTextMessage">
              {{ $t("invalidFormat") }}
            </div>
          </b-col>
          <b-col v-if="showErrorDuplicatePlate">
            <div class="mainErrorTextMessage">
              {{ $t("registerNewVehicle.duplicateError") }}
            </div>
          </b-col>
        </b-row>

        <div v-show="showInfo">
          <Input
            type="text"
            :label="$t('registerNewVehicle.brand')"
            :tooltip="$t('registerNewVehicle.brandDesc')"
            :isRequired="isBrandNameError"
            :value="vehicleInfo.brandName"
            v-on:inputValue="onChangeBrandName"
            :showAsterisk="true"
          />

          <Input
            type="text"
            :label="$t('registerNewVehicle.model')"
            :tooltip="$t('registerNewVehicle.modelDesc')"
            :isRequired="isModelNameError"
            :value="vehicleInfo.modelName"
            v-on:inputValue="onChangeModelName"
            :showAsterisk="true"
          />

          <Input
            type="text"
            :label="$t('registerNewVehicle.color')"
            :tooltip="$t('registerNewVehicle.colorDesc')"
            :isRequired="isColorError"
            :value="vehicleInfo.color"
            v-on:inputValue="onChangeColor"
            :showAsterisk="true"
          />

          <Input
            type="number"
            :label="$t('registerNewVehicle.mileage')"
            :tooltip="$t('registerNewVehicle.mileageDesc')"
            :isRequired="isOdoMeterError"
            :value="odoMeter"
            v-on:inputValue="onChangeOdoMeter"
            :showAsterisk="true"
          />

          <Input
            type="select"
            :label="$t('registerNewVehicle.collect')"
            :tooltip="$t('registerNewVehicle.collectDesc')"
            :isRequired="isMountTypeError"
            :optionValues="getMountTypes"
            v-on:inputValue="onChangeMountType"
            :placeholder="$t('registerNewVehicle.collectPlaceholder')"
            :showAsterisk="true"
          />

          <Input
            type="select"
            :label="$t('registerNewVehicle.org')"
            :tooltip="$t('registerNewVehicle.orgDesc')"
            :optionValues="[getChosenOrganization]"
            :value="
              getChosenOrganization ? getChosenOrganization.value : undefined
            "
            :isDisabled="true"
          />

          <Input
            type="select"
            :label="$t('registerNewVehicle.region')"
            :tooltip="$t('registerNewVehicle.regionDesc')"
            :optionValues="[getChosenRegion]"
            :value="getChosenRegion ? getChosenRegion.value : undefined"
            :isDisabled="true"
          />

          <Input
            type="text"
            :label="$t('registerNewVehicle.notes')"
            :tooltip="$t('registerNewVehicle.notesDesc')"
            :placeholder="$t('registerNewVehicle.notesPlaceholder')"
            :value="writtenNotes"
            v-on:inputValue="writtenNotes = $event"
            :showAsterisk="true"
          />

          <div class="mainErrorTextMessage" v-if="showError">
            {{ $t("required") }}
          </div>

          <Button
            :label="$t('registerNewVehicle.save')"
            @clicked="saveVehicle"
          />
        </div>
        <Cancel />
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { getVehicles, postVehicle } from "../../services/reenapi";
import { getVehicleInformation } from "../../services/kjoretoydata";

export default {
  data() {
    return {
      licensePlate: "",
      showInfo: false,
      showErrorLicensePlate: false,
      showErrorDuplicatePlate: false,
      vehicleInfo: {},
      odoMeter: null,
      mountType: "",
      writtenNotes: "",
      isBrandNameError: false,
      isModelNameError: false,
      isColorError: false,
      isOdoMeterError: false,
      isMountTypeError: false,
      showError: false,
      finishLicensePlate: false,
    };
  },
  computed: {
    ...mapGetters([
      "getScannedExistingTriplog",
      "getMountTypes",
      "getChosenOrganization",
      "getChosenRegion",
    ]),
  },

  methods: {
    ...mapActions(["setScannedExistingTriplog"]),

    onChangeBrandName(event) {
      this.vehicleInfo.brandName = event;
      if (this.isBrandNameError === true) {
        this.isBrandNameError = false;
      }
      this.removeMainErrorMessage();
    },

    onChangeModelName(event) {
      this.vehicleInfo.modelName = event;
      if (this.isModelNameError === true) {
        this.isModelNameError = false;
      }
      this.removeMainErrorMessage();
    },

    onChangeColor(event) {
      this.vehicleInfo.color = event;
      if (this.isColorError === true) {
        this.isColorError = false;
      }
      this.removeMainErrorMessage();
    },

    onChangeOdoMeter(event) {
      this.odoMeter = event;
      if (this.isOdoMeterError === true) {
        this.isOdoMeterError = false;
      }
      this.removeMainErrorMessage();
    },

    onChangeMountType(event) {
      this.mountType = event;
      if (this.isMountTypeError === true) {
        this.isMountTypeError = false;
      }
      this.removeMainErrorMessage();
    },

    removeMainErrorMessage() {
      if (
        this.isBrandNameError === false &&
        this.isModelNameError === false &&
        this.isColorError === false &&
        this.isOdoMeterError === false &&
        this.isMountTypeError === false
      ) {
        this.showError = false;
      }
    },

    checkErrors() {
      this.isBrandNameError = this.vehicleInfo.brandName === "";
      this.isModelNameError = this.vehicleInfo.modelName === "";
      this.isColorError = this.vehicleInfo.color === "";
      this.isOdoMeterError = this.odoMeter === null;
      this.isMountTypeError = this.mountType === "";
    },

    async saveVehicle() {
      this.checkErrors();

      if (
        !this.isBrandNameError &&
        !this.isModelNameError &&
        !this.isColorError &&
        !this.isOdoMeterError &&
        !this.isMountTypeError
      ) {
        let newVehicle = this.createNewVehicle();
        await postVehicle(newVehicle);
        this.setScannedExistingTriplog({});
        this.$router.push("submitsuccess");
      } else {
        this.showError = true;
      }
    },

    async getVehicleInfo() {
      this.licensePlate = this.licensePlate.replace(/\s/g, "");

      let getData = await getVehicles();
      let vehicles = getData.data;

      if (
        vehicles.some((vehicle) => {
          return vehicle.license_plate
            ? vehicle.license_plate.number === this.licensePlate
            : false;
        })
      ) {
        this.showErrorLicensePlate = false;
        this.showErrorDuplicatePlate = true;
      } else {
        this.vehicleInfo = await getVehicleInformation(this.licensePlate);

        if (this.vehicleInfo.success === false) {
          this.showErrorLicensePlate = true;
          this.showInfo = false;
          this.finishLicensePlate = false;
        } else {
          this.showErrorLicensePlate = false;
          this.showErrorDuplicatePlate = false;
          this.showInfo = true;
          this.finishLicensePlate = true;
        }
      }
    },

    createNewVehicle() {
      let regexmatch =
        this.vehicleInfo.reRegTax.firstTimeRegistrationDate.match(/\((.*)\)/);

      let reRegDate = new Date(parseInt(regexmatch[1]));
      let now = new Date();

      let newVehicle = {
        manufacturer: {
          name: this.vehicleInfo.brandName,
        },
        model: {
          name: this.vehicleInfo.modelName,
          height: this.vehicleInfo.height,
          length: this.vehicleInfo.length,
          width: this.vehicleInfo.width,
        },
        license_plate: {
          number: this.licensePlate.toUpperCase(),
          registration_date: reRegDate,
        },
        registered_at: reRegDate,
        unit: this.getScannedExistingTriplog,
        organization: this.getChosenOrganization,
        region: this.getChosenRegion,
        odo_meter: {
          value: this.odoMeter,
          timestamp: now.toISOString(),
        },
        fuel_types: this.vehicleInfo.fuelName,
        color: this.vehicleInfo.color,
        co2_emissions: this.vehicleInfo.co2,

        totalWeightAllowed: this.vehicleInfo.totalWeightAllowed,
        mount_type: this.mountType,
        notes: this.writtenNotes,
      };
      return newVehicle;
    },
  },
};
</script>

<style scoped>
.mainErrorTextMessage {
  color: #dc3545;
  font-size: 16px;
  text-align: center;
}
.vehicleBtn {
  text-align: center;
  border-radius: 5px;
  width: 224px;
  font-size: 1rem;
  color: #ffe893;
  border: solid #ffe893 2px;
  background-color: transparent;
}
</style>