<template>
  <div class="logo-container">
    <img src="../assets/LogoWhite.png" alt="logo" @click="cancel" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions([
      "setNewContainer",
      "setScannedExistingContainer",
      "setScannedExistingUnit",
    ]),

    cancel() {
      this.setNewContainer({});
      this.setScannedExistingContainer({});
      this.setScannedExistingUnit({});
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.logo-container {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
}

img {
  height: 30px;
  cursor: pointer;
}
</style>