<template>
  <div class="background">
    <b-container>
      <b-row>
      <b-col>
        <div class="checkFinished">
          <br><br><br>
          <b-icon-check-circle
            font-scale="5"
            style="color: #ffe893"
          ></b-icon-check-circle>
          <br /><br />
          <span>{{$t('boxSuccess.created')}}</span>
        </div>
      </b-col>
      </b-row>

      <b-row>
        <b-col>
          <Button :label="$t('boxSuccess.button')" @clicked="registerNew" />
          <Cancel :clicked="cancelBox" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { postBox, deleteBox } from "../../services/reenapi";

export default {
data() {
  return {
    blankBox: {
      tenant_id: "37999d93-6b9a-47cb-85c3-289147055a47",
      qr_code: "",
      serial_number: "",
      units: [],
    }
  }
},
  methods: {
    ...mapActions(["setBoxes"]),
    
    async registerNew() {
      await postBox(this.blankBox);
      await this.setBoxes();
      this.$router.push('registernewbox');
    },

    async cancelBox() {
      let boxes = this.getBoxes;
      let list = [];
     
      for (var i = 0; i < boxes.length; i++) {
        if (this.newSerialNumber === boxes[i].serial_number) {
          list.push(boxes[i]);
        }
      }
      if (list[0].serial_number === "") {
        this.fetchSerialNumber = list[0].serial_number;
        this.setNewBox(list[0])
      }
      await deleteBox(this.getNewBox);
    }
  }
};
</script>

<style scoped>
.checkFinished {
  margin-top: 50px;
}
span {
  color: white;
  font-size: 1.3rem;
}
.submitButton {
  position: relative;
  border-radius: 25px;
  width: 150px;
  padding: 10px;
  margin-top: 30px;
  font-size: 1.1rem;
  color: #ffe893;
  border: solid #ffe893 3px;
  background-color: transparent;
}
</style>
