import axios from "axios"
import https from 'https'
import store from "../store/index";

// const baseURL = 'http://localhost:5000/api/'
// const baseURL = 'https://reenapi-staging.azurewebsites.net/api'
const baseURL = 'https://reenapi.azurewebsites.net/api'

async function apiRequest(method, endpoint, payload) {
    if (store.getters.token === null) {
        await store.dispatch("setToken");
    }

    let request = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false
        }),
        headers: {
            Authorization: `Bearer ${store.getters.token}`,
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
        },
        method,
        baseURL,
        url: endpoint,
        data: payload,
    };

    return await axios(request).catch((error) => {
        return error.response;
        // throw new Error(error);
    });
}

/**
 * Registrerer en ny container i databasen vår. Da skal containeren bruke den
 * samme id-en som den Equipment som vi bruker til å synce med Abax-databasen.
 */
export async function updateNewContainer(container) {
    if (!container) {
        throw new Error("container is null or undefined");
    }

    if (!container.unit) {
        throw new Error("container.unit is null or undefined");
    }

    await removeUnitFromBox(container.unit);

    // Does the unit have a dummy equipment?
    const equipment_id = container.unit.equipment_id;
    if (!equipment_id) {
        try {
            return await postContainer(container);
        } catch {
            throw new Error("could not POST container, is it already in the database with an inaccessable tenant?");
        }
    }

    let containerResponse = await getContainerById(equipment_id);
    if (containerResponse.status !== 404 && containerResponse.data.length > 0) {
        throw new Error("container already exists in database");
    }

    if ((await getEquipment(equipment_id)).status === 200) {
        await deleteEquipment(equipment_id);
    }

    container.id = equipment_id;
    return await putContainer(container);
}

/**
 * Replaces a conainer's unit with a new unit.
 */
export async function swapContainer(container) {
    if (!container) {
        throw new Error("container is null or undefined");
    }

    removeUnitFromBox(container.unit);

    // Does the new unit have a dummy equipment?
    const equipment_id = container.unit.equipment_id;
    if (equipment_id) {
        let containerResponse = await getContainerById(equipment_id);
        if (containerResponse.status !== 404 && containerResponse.data.length > 0) {
            throw new Error("container already exists in database");
        }

        if ((await getEquipment(equipment_id)).status === 200) {
            await deleteEquipment(equipment_id);
        }
    }

    return await putContainer(container);
}

/*
 * Equipment
 */

export async function getEquipment(id) {
    return await apiRequest("GET", `equipment/${id}`);
}

export async function getEquipmentBySerialNumber(serialNumber) {
    return await apiRequest("GET", `equipment?serial_number=${serialNumber}`);
}

export async function deleteEquipment(equipment_id) {
    return await apiRequest("DELETE", `equipment/${equipment_id}`);
}

/*
 * Container
 */

export async function getContainer(qr) {
    return await apiRequest("GET", `equipment/containers?qr_code=${qr}`);
}

export async function getContainerById(id) {
    return await apiRequest("GET", `equipment/containers/${id}`);
}

export async function putContainer(container) {
    return await apiRequest("PUT", `equipment/containers/${container.id}`, container);
}

export async function postContainer(container) {
    return await apiRequest("POST", `equipment/containers`, container);
}

export async function deleteContainer(container) {
    return await apiRequest("DELETE", `equipment/containers/${container.id}`);
}

export async function getUseTypes() { // TODO: Not needed? 
    return await apiRequest("GET", "equipment/containers/UseType");
}

export async function getManufacturers() {
    return await apiRequest("GET", "Manufacturers");
}

/*
 * ContainerType
 */

export async function getContainerTypes() {
    return await apiRequest("GET", "equipment/containers/ContainerTypes/container_type");
}

export async function postContainerType(containerType) {
    return await apiRequest("POST", "equipment/containers/ContainerTypes", containerType);
}

export async function getMountTypes() {
    return await apiRequest("GET", "equipment/containers/ContainerTypes/mount_type");
}

/*
 * Organization
 */

export async function getOrganizations() {
    return await apiRequest("GET", "Organizations");
}

export async function getOrganization(id) {
    if (!id) throw new Error("id is null or undefined");
    return await apiRequest("GET", `Organizations/${id}`);
}

export async function getRegions(organisationId) {
    return await apiRequest("GET", `Organizations/${organisationId}/regions`);
}

/*
 * Assorted
 */

export async function getStates() {
    return await apiRequest("GET", "equipment/states");
}

export async function getVehicles(params) {
    return await apiRequest("GET", `vehicles${toQuery(params)}`);
}

export async function postVehicle(vehicle) {
    return await apiRequest("POST", "vehicles", vehicle);
}

/*
 * Boxes
 */

export async function getBoxes() {
    return await apiRequest("GET", "Boxes");
}

export async function getBoxesByQr(qr) {
    return await apiRequest("GET", `boxes?qr_code=${qr}`)
}

export async function postBox(box) {
    return await apiRequest("POST", `boxes`, box);
}

export async function putBox(box) {
    return await apiRequest("PUT", `boxes/${box.id}`, box);
}

export async function deleteBox(box) {
    return await apiRequest("DELETE", `boxes/${box.id}`, box);
}

/*
 * Unit
 */

export async function getUnit(id) {
    return await apiRequest("GET", `iot?serial_number=${id}`);
}

export async function putUnit(unit) {
    return await apiRequest("PUT", `iot/${unit.id}`, unit);
}

export async function deleteUnit(id) {
    return await apiRequest("DELETE", `iot/${id}`);
}

export async function getSwapCauses() {
    return await apiRequest("GET", "iot/swapped_unit");
}

async function removeUnitFromBox(unit) {
    if (!unit) {
        throw new Error("unit is null or undefined");
    }

    unit.box_id = null;
    return await putUnit(unit);
}

/**
 * Prøver å finne og slette fra Equipment med `serial_number`.
 * Returnerer `null` hvis Equipment ikke funnet.
 * Returnerer `{Guid}` hvis Equipment funnet og slettet.
 */
// async function deleteEquipmentBySerialNumber(serialNumber) {
//     let equipmentToDelete = await getEquipmentBySerialNumber(serialNumber);
//     if (equipmentToDelete.data.length > 0) {
//         await deleteEquipment(equipmentToDelete.data[0].id);
//         return equipmentToDelete.data[0].id;
//     }
//     return null;
// }

/**
 * Transforms an object `params` to a HTTP query string on the form
 *   `?{key1}={value1}&{key2}={value2}&...`
 * @param {object} params
 */
function toQuery(params) {
    if (params === undefined) return "";

    let values = [];
    for (let key in params) {
        values.push(`${key}=${params[key]}`);
    }
    return `?${values.join("&")}`;
}