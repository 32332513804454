<template>
  <div>
    <b-container>
      <h1>{{ $t("swapQrCode.title") }}</h1>

      <div
        v-for="(item, index) in createContainerInformationObject"
        :key="index"
      >
        <Input
          type="text"
          :label="item.name"
          :tooltip="item.tooltip"
          :isDisabled="true"
          :value="item.value"
        />
      </div>

      <div>
        <b-row>
          <b-col>
            <div @click="$router.push('maintenance')">
              <b-icon-x-circle font-scale="4" style="color: #ff6a6a">
              </b-icon-x-circle>
            </div>
          </b-col>
          <b-col>
            <div @click="updateContainer(getScannedExistingContainer)">
              <b-icon-check-circle
                font-scale="4"
                style="color: #65bf95"
              ></b-icon-check-circle>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <Geolocation v-on:submit="location = $event"></Geolocation>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as Axios from "../../../services/reenapi";
import Geolocation from "../../../components/location/Geolocation";

export default {
  components: {
    Geolocation
  },
  computed: {
    ...mapGetters(["getScannedExistingContainer", "getScannedQrContainer"]),

    createContainerInformationObject() {
      let existingContainerInformation = this.getScannedExistingContainer;
      return [
        {
          name: this.$t("swapQrCode.container"),
          value: existingContainerInformation.alias,
          tooltip: this.$t("swapQrCode.containerDesc"),
        },
        {
          name: this.$t("swapQrCode.oldQr"),
          value: existingContainerInformation.qr_code,
          tooltip: this.$t("swapQrCode.oldQrDesc"),
        },
        {
          name: this.$t("swapQrCode.newQr"),
          value: this.getScannedQrContainer,
          tooltip: this.$t("swapQrCode.newQrDesc"),
        },
      ];
    },
  },
  methods: {
    ...mapActions(["setScannedQrContainer"]),

    async updateContainer(existingContainer) {

      let newQrCode = this.getScannedQrContainer;

      existingContainer.qr_code = newQrCode;
      existingContainer.alias =
        this.getScannedQrContainer + " " + existingContainer.container_type.name;

      let location = {
        latitude: this.location.coords.latitude,
        longitude: this.location.coords.longitude,
        timestamp: new Date(),
        signal_source: "gps",
      };
      existingContainer.location = location;

      try {
        await Axios.swapContainer(existingContainer);
        this.setScannedQrContainer();
        this.$router.push("swapsuccess");
      } catch (err) {
        console.error("der happn err:", err);
      }
    },
  },
};
</script>

<style>
</style>