<template>
  <div class="detailCard">
    <b-container>
      <div class="topline">
        <h4>{{ $t("addUnitManually.title") }}</h4>
        <div @click="$emit('cancel')">
          <Icon class="exitCross" :data="closeIcon" />
        </div>
      </div>
      <b-row>
        <b-col>
          <div>
            {{ $t("addUnitManually.description") }}
          </div>
          <Input
            type="text"
            :label="$t('addUnitManually.serial')"
            :tooltip="$t('addUnitManually.tooltip')"
            :value="manualSerialNumber"
            v-on:inputValue="manualSerialNumber = normalizeSerialNumber($event)"
            :showAsterisk="true"
          />
          <b-row>
            <b-col v-show="showError">
              <div class="mainErrorTextMessage">
                {{ $t("addUnitManually.wrongSerialNumber") }}
              </div>
            </b-col>
          </b-row>
          <Button
            :label="$t('addUnitManually.nextButton')"
            @clicked="getUnitManually"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Icon from "@/components/icons/Icons.vue";
import { Close } from "../icons/icons";
import { getUnit } from "../../services/reenapi";
import { isValidUnitCode, isValidTriplogCode } from "../../utils/validators";

export default {
  components: {
    Icon,
  },
  data() {
    return {
      closeIcon: Close("white", 35, {}),
      manualSerialNumber: "",
      showError: false,
    };
  },
  computed: {
    ...mapGetters(["getCurrentTheme"]),
  },
  methods: {
    ...mapActions([
      "setScannedExistingUnit",
      "setScannedExistingTriplog",
    ]),

    async getUnitManually() {
      let unit = this.manualSerialNumber;

      if (unit === "") return;

      if (isValidUnitCode(unit)) {
        let response = await getUnit(unit);
        if (response.status === 200 && response.data.length > 0) {
          this.setScannedExistingUnit(response.data[0]);
          this.$emit("success");
        }
      } else if (isValidTriplogCode(unit)) {
        let response = await getUnit(unit);
        if (response.status === 200 && response.data.length > 0) {
          this.setScannedExistingTriplog(response.data[0]);
          this.$router.push("registernewvehicle");
        }
      } else {
        this.showError = true;
      }
    },

    normalizeSerialNumber(serial_number) {
      return serial_number.toUpperCase().replace(/\s/g, "");
    },
  },
};
</script>

<style scoped>
.detailCard {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}

.container {
  background-color: black;
  border: 2px solid #f7f8f8;
  border-radius: 20px;
  position: absolute;
  font-size: 1.2rem;
  z-index: 999;
  overflow: auto;
  width: 80%;
  height: 70%;
  top: 10%;
}

.topline {
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  color: #ffe893;
  padding: 5px;
  margin-bottom: 20px;
}
.exitCross {
  position: relative;
  top: -5px;
  float: right;
  cursor: pointer;
  color: #ffe893 !important;
}
.mainErrorTextMessage {
  color: #dc3545;
  font-size: 16px;
  text-align: center;
}
.divider {
  border-bottom: 1px solid #ffe893;
  margin-bottom: 10px;
  margin-left: 0px;
}
</style>
