<template>
  <div>
    <div class="overlay">
      <qrcode-stream @decode="onDecode"></qrcode-stream>
    </div>

    <div class="overlay-field">
      <div
        class="overlay-text qr"
        v-if="itemThatShouldBeScanned === 'container'"
      >
        <strong>{{ $t("camera.container") }}</strong>
      </div>

      <transition name="checkMarkFadeOut">
        <div class="checkIcon" v-if="showCheckMark">
          <b-icon-check-circle
            font-scale="5"
            style="color: #65bf95"
          ></b-icon-check-circle>
        </div>
      </transition
      >
      <div
        class="overlay-text serial"
        v-if="itemThatShouldBeScanned === 'unit'"
      >
        <strong>{{ $t("camera.unit") }}</strong>
      </div>
      <div
        class="overlay-text serial"
        v-if="itemThatShouldBeScanned === 'containertype'"
      >
        <strong>{{ $t("camera.containerType") }}</strong>
      </div>
      <div
        class="overlay-text serial"
        v-if="itemThatShouldBeScanned === 'newQr'"
      >
        <strong>{{ $t("camera.qr") }}</strong>
      </div>
      <div class="overlay-text qr" v-if="itemThatShouldBeScanned === 'box'">
        <strong>{{ $t("camera.box") }}</strong>
      </div>
      <div class="icon">
        <b-icon-fullscreen></b-icon-fullscreen>
      </div>
    </div>

    <div v-if="invalidCode" class="error-field">
      <div class="errorMessage">
        {{ $t("invalidCode") }}
      </div>
    </div>

    <div class="overlay-bottom">
      <slot />
      <Cancel :clicked="cancelAction" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Camera",

  props: {
    itemThatShouldBeScanned: {
      type: String,
      required: true,
      validator: (propValue) => {
        // 'unit' er både sensor, mini, og kjørebok, 'container' er container
        if (
          propValue === "unit" ||
          propValue === "container" ||
          propValue === "containertype" ||
          propValue === "newQr" ||
          propValue === "box"
        ) {
          return true;
        } else {
          return false;
        }
      },
    },
    cancelAction: {
      type: Function,
      required: false,
    },
    invalidCode: {
      type: Boolean,
      required: true,
    },
    showCheckMark: Boolean,
  },

  data() {
    return {
      error: "",
    };
  },

  methods: {
    onDecode(code) {
      if (code.startsWith("http://")) {
        let result = code.split("/").pop().toUpperCase();
        this.$emit("scanned", result);
      } else {
        let result = code.toUpperCase();
        this.$emit("scanned", result);
      }
      this.$forceUpdate();
    },

    onError(error) {
      error.name === "error";
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: You need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: No camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: Secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: Is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: Installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
  },
};
</script>

<style scoped>
.overlay {
  z-index: 1;
  color: black;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.overlay-field {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-bottom {
  z-index: 200;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay-text {
  display: inline;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 1.3em;
}

.overlay-text.serial {
  background-color: #ffe893;
  color: black;
}

.overlay-text.qr {
  background-color: #65bf95;
  color: #f7f8f8;
  text-shadow: 0px 0px 3px black;
}

.icon {
  z-index: 2;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkIcon {
  z-index: 2;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkIcon svg {
  width: 25vw;
  height: 25vh;
}

.checkMarkFadeOut-leave-active {
  transition: opacity 0.5s;
}

.checkMarkFadeOut-leave-to {
  opacity: 0;
}

.icon svg {
  width: 50vw;
  height: 50vh;
}

.cancelButton {
  background: rgba(0, 0, 0, 0.75) !important;
  color: white;
}
.error-field {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.errorMessage {
  background-color: #ff6a6a;
  display: inline;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 1.3em;
  color: black;
}
</style>
