import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "../auth/authGuard";

// Views
import OnBoarding from "../views/Onboarding.vue";
import HowToUse from "../views/HowToUse.vue";
import TestPage from "../views/Test";

// Box
import RegisterNewBox from "../views/box/RegisterNewBox";
import ScanBox from "../views/box/ScanBox";
import BoxSuccess from "../views/box/BoxSuccess";
import BoxMaintenance from "../views/box/BoxMaintenance";
import DriverKit from "../views/box/DriverKit"

// Device
import RegisterNewDevice from "../views/device/RegisterNewDevice.vue";
import RegisterNewVehicle from "../views/device/RegisterNewVehicle";
import SubmitSuccess from "../views/device/SubmitSuccess";

// Device - Container
import RegisterNewContainer from "../views/device/container/RegisterNewContainer.vue";
import Maintenance from "../views/device/container/Maintenance";
import RestoreContainer from "../views/device/container/RestoreContainer";

// Device - Swap
import SwapDevice from "../views/device/swap/SwapDevice.vue";
import SwapQrCamera from "../views/device/swap/SwapQrCamera";
import SwapQrForm from "../views/device/swap/SwapQrForm";
import SwapQrSuccess from "../views/device/swap/SwapQrSuccess";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "onboarding",
        component: OnBoarding,
    },
    {
        path: "/testpage",
        name: "testpage",
        component: TestPage,
        beforeEnter: authGuard
    },
    {
        path: "/registernewcontainer",
        name: "registernewcontainer",
        component: RegisterNewContainer,
        beforeEnter: authGuard
    },
    {
        path: "/submitsuccess",
        name: "SubmitSucces",
        component: SubmitSuccess,
        beforeEnter: authGuard
    },
    {
        path: "/swapdevice",
        name: "SwapDevice",
        component: SwapDevice,
        beforeEnter: authGuard
    },
    {
        path: "/registernewdevice",
        name: "RegisterNewDevice",
        component: RegisterNewDevice,
        beforeEnter: authGuard
    },
    {
        path: "/registernewvehicle",
        name: "RegisterNewVehicle",
        component: RegisterNewVehicle,
        beforeEnter: authGuard
    },
    {
        path: "/maintenance",
        name: "Maintnance",
        component: Maintenance,
        beforeEnter: authGuard
    },
    {
        path: "/swapqrcamera",
        name: "SwapQrCamera",
        component: SwapQrCamera,
        beforeEnter: authGuard
    },
    {
        path: "/swapqr",
        name: "SwapQrForm",
        component: SwapQrForm,
        beforeEnter: authGuard
    },
    {
        path: "/howtouse",
        name: "HowToUse",
        component: HowToUse,
        beforeEnter: authGuard
    },
    {
        path: "/registernewbox",
        name: "RegisterNewBox",
        component: RegisterNewBox,
        beforeEnter: authGuard
    },
    {
        path: "/swapsuccess",
        name: "SwapQrSuccess",
        component: SwapQrSuccess,
        beforeEnter: authGuard
    },
    {
        path: "/boxsuccess",
        name: "BoxSuccess",
        component: BoxSuccess,
        beforeEnter: authGuard
    },
    {
        path: "/scanbox",
        name: "ScanBox",
        component: ScanBox,
        beforeEnter: authGuard
    },
    {
        path: "/boxmaintenance",
        name: "BoxMaintenance",
        component: BoxMaintenance,
        beforeEnter: authGuard
    },
    {
        path: "/restorecontainer",
        name: "RestoreContainer",
        component: RestoreContainer,
        beforeEnter: authGuard
    },
    {
        path: "/driverkit",
        name: "DriverKit",
        component: DriverKit,
        beforeEnter: authGuard
    },
];

const router = new VueRouter({
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;