<template>
  <div>
    <Geolocation v-on:submit="location = $event"></Geolocation>
    <ManualSwap
      class="detailCard"
      v-if="isShowingManualWindow"
      @closeDetails="isShowingManualWindow = false"
    />
    <Camera
      v-if="isShowingContainerCamera"
      v-on:scanned="saveExistingContainer"
      itemThatShouldBeScanned="container"
      :invalidCode="qrCodeInvalid"
    >
      <Button
        :label="$t('swapQrCode.typeManually')"
        @clicked="isShowingManualWindow = true"
      />
    </Camera>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isValidQrCode } from "../../../utils/validators";
import { getContainer, putContainer } from "../../../services/reenapi";
import Camera from "../../../components/camera/CameraComponent";
import ManualSwap from "../../../components/popups/ManualSwap.vue";
import Geolocation from "../../../components/location/Geolocation.vue";

export default {
  components: {
    Camera,
    ManualSwap,
    Geolocation
  },
  data() {
    return {
      isShowingQrCamera: true,
      isShowingContainerCamera: true,
      isShowingManualWindow: false,
      qrCodeInvalid: false,
    };
  },
  computed: {
    ...mapGetters(["getScannedExistingContainer"]),
  },
  methods: {
    ...mapActions(["setScannedExistingContainer"]),

    async saveExistingContainer(container) {
      if (container === null) return;

      if (!isValidQrCode(container)) {
        this.qrCodeInvalid = true;
        return;
      }

      console.log(container);

      let response = await getContainer(container);
      console.log(response);

      if (response.status === 200 && response.data.length > 0) {
        let container = response.data[0];
        let newLocation = {
          latitude: this.location.coords.latitude,
          longitude: this.location.coords.longitude,
          timestamp: new Date(),
          signal_source: "gps",
        }
        container.location = newLocation;
        await putContainer(container);
        this.setScannedExistingContainer(container);
        this.qrCodeInvalid = false;
        this.$router.push("maintenance");
        this.isShowingContainerCamera = false;
      } else {
        this.qrCodeInvalid = true;
      }
    },
  },
};
</script>

<style>
.overlay-field {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.errorMessage {
  background-color: #ff6a6a;
  display: inline;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 1.3em;
  color: black;
}
.baseButton {
  background: rgba(0, 0, 0, 0.75) !important;
  color: white;
}
.detailCard {
  z-index: 1000;
}
</style>
