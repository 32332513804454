<template>
  <div>
    <Camera
      v-if="isShowingCamera"
      v-on:scanned="saveExistingBox"
      itemThatShouldBeScanned="box"
      :invalidCode="qrCodeInvalid"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { isValidQrCode } from "../../utils/validators";
import { getBoxesByQr } from "../../services/reenapi";
import Camera from "../../components/camera/CameraComponent.vue";

export default {
  components: {
    Camera,
  },
  data() {
    return {
      isShowingCamera: true,
      qrCodeInvalid: false,
    };
  },
  computed:{
    ...mapGetters(["getScannedBox"]),
  },
  methods: {
    ...mapActions(["setScannedBox"]),

    async saveExistingBox(box) {
      if (box === null) return;

      if (!isValidQrCode(box)) {
        this.qrCodeInvalid = true;
        return;
      }

      let response = await getBoxesByQr(box);
      let isSuperAdmin = this.$auth.user["https://reen.com/roles"].includes("superadmin");

      if (response.status === 200 && response.data.length > 0 && isSuperAdmin) {
        this.setScannedBox(response.data[0]);
        this.$router.push("boxmaintenance");
      }  
      else if (response.status === 200 && response.data.length > 0) {
        this.setScannedBox(response.data[0])
        console.log(this.getScannedBox);
        this.$router.push("driverkit");
      } 
      else {
        this.qrCodeInvalid = true;
      }
    },
  },
};
</script>

<style>
.overlay-field {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.errorMessage {
  background-color: #ff6a6a;
  display: inline;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 1.3em;
  color: black;
}
.baseButton {
  background: rgba(0, 0, 0, 0.75) !important;
  color: white;
}
.detailCard {
  z-index: 1000;
}
</style>
