<template>
  <div>
    <b-container>
      <h1>{{ $t("swapDevice.title") }}</h1>

      <div v-for="(item, index) in createDeviceInformationObject" :key="index">
        <Input
          type="text"
          :label="item.name"
          :tooltip="item.tooltip"
          :isDisabled="true"
          :value="item.value"
        />
      </div>

      <Input
      type="select"
      :label="$t('swapDevice.cause')"
      :tooltip="$t('swapDevice.causeTooltip')"
      :isRequired="isCauseError"
      :optionValues="getSwapCauses"
      :showAsterisk="true"
      v-on:inputValue="onChangeCause"
      :placeholder="$t('swapDevice.causePlaceholder')"
    />

      <div v-if="isHidden">
        <b-row>
          <b-col>
            <div @click="$router.push('/')">
              <b-icon-x-circle font-scale="4" style="color: #ff6a6a">
              </b-icon-x-circle>
            </div>
          </b-col>
          <b-col>
            <div @click="updateUnit(getScannedExistingContainer)">
              <b-icon-check-circle
                font-scale="4"
                style="color: #65bf95"
              ></b-icon-check-circle>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="!isHidden">
        <Button label="Tilbake" @clicked="goBack" />
      </div>
    </b-container>
    <Geolocation v-on:submit="location = $event"></Geolocation>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Geolocation from "../../../components/location/Geolocation";
import { swapContainer, putUnit } from "../../../services/reenapi";

export default {
  components: {
    Geolocation
  },
  data() {
    return {
      swapCause: "",
      isHidden: true,
      isCauseError: false
    };
  },
  computed: {
    ...mapGetters([
      "getScannedExistingContainer",
      "getScannedExistingUnit",
      "getSwapCauses"
    ]),

    createDeviceInformationObject() {
      let container = this.getScannedExistingContainer;
      let unit = this.getScannedExistingUnit;
      return [
        {
          name: this.$t("swapDevice.container"),
          value: container.alias,
          tooltip: this.$t("swapDevice.containerDesc"),
        },
        {
          name: this.$t("swapDevice.oldUnit"),
          value: container.unit ? container.unit.serial_number : null,
          tooltip: this.$t("swapDevice.oldUnitDesc"),
        },
        {
          name: this.$t("swapDevice.newUnit"),
          value: unit.serial_number,
          tooltip: this.$t("swapDevice.newUnitDesc"),
        },
      ];
    },
  },

  methods: {
    ...mapActions([
      "setScannedExistingContainer",
      "setScannedExistingUnit",
      "setNewContainer",
    ]),

    onChangeCause(event) {
      this.swapCause = event;
       if (this.isCauseError === true) {
         this.isCauseError = false;
       }
    },

    async updateUnit(existingContainer) {
      this.swapCause === ""
        ? (this.isCauseError = true)
        : (this.isCauseError = false);

      if (this.isCauseError) {
        return;
      }

      let newUnit = this.getScannedExistingUnit;

      let container = {
        id: existingContainer.id,
        serial_number: newUnit.serial_number,
        tenant_id: existingContainer.tenant_id,
        unit: {
          ...newUnit,
          tenant_id: existingContainer.tenant_id,
          box_id: null
        },
        location: {
          latitude: this.location.coords.latitude,
          longitude: this.location.coords.longitude,
          timestamp: new Date(),
          signal_source: "gps",
        },
      };

      let unitInContainer = this.getScannedExistingContainer.unit;

      let oldUnit = {
        ...unitInContainer,
        tenant_id: existingContainer.tenant_id,
        swapped: this.swapCause,
        swap_date: new Date(),
        swapped_with_id: newUnit.id,
      };

      try {
        await putUnit(oldUnit);
        await swapContainer(container);

        this.setNewContainer({});
        this.setScannedExistingContainer({});
        this.setScannedExistingUnit({});
        this.$router.push("submitsuccess");
      } catch (err) {
        console.error("der happn err:", err);
      }
    },
    goBack() {
      this.setNewContainer({});
      this.setScannedExistingContainer({});
      this.setScannedExistingUnit({});
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.detailCard {
  z-index: 1000;
}
</style>
