import Vue from "vue";
import Vuex from "vuex";

import token from "./modules/token"
import data from "./modules/getData"
import scannedData from "./modules/scannedData"
import registerData from "./modules/registerData"

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        token,
        data,
        scannedData,
        registerData,
    },
});