<template>
  <div>
    <b-container>
      <div v-show="isShowingInformation">
        <h1>{{$t('restoreContainer.title')}}</h1>

        <span>
          {{$t('restoreContainer.explanation')}}
        </span>

        <h5>{{$t('restoreContainer.important')}}</h5>
        <span>
          {{$t('restoreContainer.importantMessage')}}
        </span
        >

        <h5>{{$t('restoreContainer.areYouSure')}}</h5>

        <b-row>
          <b-col>
            <div @click="goBack">
              <b-icon-x-circle font-scale="4" style="color: #ff6a6a">
              </b-icon-x-circle>
            </div>
          </b-col>
          <b-col>
            <div @click="restoreContainer">
              <b-icon-check-circle
                font-scale="4"
                style="color: #65bf95"
              ></b-icon-check-circle>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-show="!isShowingInformation">
        <span>{{$t('restoreContainer.hasBeenDeleted')}}</span>
        <h5>{{$t('restoreContainer.rememberMessage')}}</h5>
        <Button label="Tilbake" @clicked="$router.push('/')" />
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { putUnit, deleteEquipment } from "../../../services/reenapi";

export default {
  data() {
    return {
      isShowingInformation: true,
    };
  },
  computed: {
    ...mapGetters(["getScannedExistingContainer"]),
  },

  methods: {
    async restoreContainer() {
      let unitInContainer = this.getScannedExistingContainer.unit;

      let oldUnit = {
        ...unitInContainer,
        swapped: "Restored",
        swap_date: new Date(),
      };

      try {
        await putUnit(oldUnit);
        await deleteEquipment(this.getScannedExistingContainer.id);
        this.isShowingInformation = !this.isShowingInformation;
      } catch (err) {
        console.error("der happn err:", err);
      }
    },

    goBack() {
      this.$router.push("maintenance");
    },
  },
};
</script>

<style scoped>
h5 {
  color: #ff6a6a;
  margin-top: 20px;
}
</style>
