<template>
  <div>
    <b-container>
      <div v-if="isBoxFixed">
        <span @click="$router.push('/')">{{ $t("howTo.back") }}</span>
        <h1>{{ $t("maintenanceBox.title") }}</h1>

        <Input
          type="text"
          :label="$t('maintenanceBox.qr')"
          :tooltip="$t('maintenanceBox.qrTooltip')"
          :isDisabled="true"
          :value="getScannedBox.qr_code"
        />

        <Input
          type="select"
          :label="$t('maintenanceBox.organization')"
          :tooltip="$t('maintenanceBox.organizationTooltip')"
          :placeholder="$t('maintenanceBox.organizationPlaceholder')"
          :optionValues="getOrganizations"
          @inputValue="setChosenBoxOrganization"
          :value="
            getScannedBox.organization ? getScannedBox.organization.id : undefined
          "
          :showAsterisk="true"
        />

        <Input
          type="select"
          :label="$t('maintenanceBox.region')"
          :tooltip="$t('maintenanceBox.regionTooltip')"
          :placeholder="$t('maintenanceBox.regionPlaceholder')"
          :optionValues="getRegions"
          @inputValue="setChosenBoxRegion"
          :value="getScannedBox.region ? getScannedBox.region.id : undefined"
          :showAsterisk="true"
        />

        <b-row>
          <b-col>
            <span>{{ $t("maintenanceBox.numberInBox") }}</span>
            <h3>{{ getScannedBox.units.length }}</h3>
          </b-col>
        </b-row>

        <Button :label="$t('maintenanceBox.button')" @clicked="updateBox" />
        <Cancel :clicked="() => setScannedBox(null)" />
      </div>

      <div v-if="!isBoxFixed">
        <b-row>
          <b-col>
            <div class="checkFinished">
              <br /><br /><br />
              <b-icon-check-circle
                font-scale="5"
                style="color: #ffe893"
              ></b-icon-check-circle>
              <br /><br />
              <span>{{ $t("maintenanceBox.reRegistered") }}</span>
            </div>
          </b-col>
        </b-row>
        <Button
          :label="$t('maintenanceBox.updateNewBox')"
          @clicked="$router.push('scanbox')"
        />
        <Button
          :label="$t('maintenanceBox.backButton')"
          @clicked="$router.push('/')"
        />
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { putBox } from "../../services/reenapi";

export default {
  data() {
    return {
      isShowingCamera: true,
      stateName: "",
      isBoxFixed: true,
    };
  },
  computed: {
    ...mapGetters([
      "getScannedBox",
      "getOrganizations",
      "getRegions",
      "getChosenBoxOrganization",
      "getChosenBoxRegion",
    ]),
  },

  methods: {
    ...mapActions([
      "setScannedBox",
      "setChosenBoxOrganization",
      "setChosenBoxRegion",
    ]),

    async updateBox() {
      let changes = {
        id: this.getScannedBox.id,
        region: this.getChosenBoxRegion,
        organization_id: this.getChosenBoxOrganization.id,
        tenant_id: this.getChosenBoxOrganization.tenant_id,
      };

      await putBox(changes);
      this.isBoxFixed = !this.isBoxFixed;
      this.setScannedBox(null);
    },
  },
};
</script>

<style scoped>
</style>
