<template>
  <div class="dateDropdowns">
    <Input
      type="select"
      :label="$t('datePicker.year')"
      :optionValues="[...listOfYears, { text: 'Ingen', value: null }]"
      @inputValue="onYearChosen"
      :showAsterisk="showAsterisk"
      :placeholder="$t('datePicker.yearDesc')"
    />

    <Input
      type="select"
      :label="$t('datePicker.month')"
      :optionValues="listOfMonths"
      @inputValue="onMonthChosen"
      :showAsterisk="showAsterisk"
      :placeholder="$t('datePicker.monthDesc')"
    />
  </div>
</template>

<script>
export default {
  props: {
    showAsterisk: Boolean,
  },
  data() {
    return {
      listOfMonths: this.$t('datePicker.monthList'),
      year: null,
      month: null,
    };
  },
  computed: {
    listOfYears() {
      let years = [];

      let latestYear = new Date(Date.now()).getFullYear();

      for (let i = 2015; i <= latestYear; i++) {
        let returnObj = {
          text: i,
          value: i,
        };
        years.push(returnObj);
      }
      return years.reverse();
    },
  },

  methods: {
    onYearChosen(year) {
      this.year = year;
      this.emitDate();
    },

    onMonthChosen(month) {
      this.month = month;
      this.emitDate();
    },

    emitDate() {
      if (this.year === null || this.month === null) {
        this.$emit("change", null);
      } else {
        let date = new Date(this.year, this.month);
        this.$emit("change", date);
      }
    },
  },
};
</script>
