export function sortAlphabetically(arr) {
    return arr.sort((a, b) => {
        if (a.text.toLowerCase() < b.text.toLowerCase()) {
            return -1;
        }
        if (a.text.toLowerCase() > b.text.toLowerCase()) {
            return 1;
        }
        return 0;
    });
}
