<template>
  <div class="background">
    <ContainerDetails
      class="detailCard"
      v-if="showDetails"
      @closeDetails="showDetails = false"
      :detailInfo="containerType"
    />
    <Camera
      v-if="isShowingTypeCamera"
      v-on:scanned="scanContainerType"
      itemThatShouldBeScanned="containertype"
      :invalidCode="cameraError"
    />

    <b-container>
      <h1>{{$t('registerContainer.title')}}</h1>
      <p>
        <em>
          {{ getChosenOrganization ? getChosenOrganization.name : $t('registerContainer.noOrg') }}<br />
          {{ getChosenRegion ? getChosenRegion.name : $t('registerContainer.noReg') }}
        </em>
      </p>

      <p style="font-family: monospace; font-size: 1.25rem">
        <span v-if="getScannedExistingUnit?.serial_number">
          <BIconCpu /> {{ getScannedExistingUnit.serial_number }}<br />
        </span>
        <BIconTrash /> {{ getScannedQrContainer }}
      </p>

      <Input
        type="select"
        :label="$t('registerContainer.mountType')"
        :tooltip="$t('registerContainer.mountTypeDesc')"
        :optionValues="[...getMountTypes, { text: 'Alle', value: null }]"
        v-on:inputValue="currentMountFilter = $event"
        :placeholder="$t('registerContainer.mountTypePlaceholder')"
      />

      <Input
        type="select"
        :label="$t('registerContainer.containerType')"
        :tooltip="$t('registerContainer.containerTypeDesc')"
        :isRequired="isTypeError"
        :optionValues="filterContainerTypes"
        v-on:inputValue="onChangeContainerType"
        :value="containerType"
        :placeholder="$t('registerContainer.containerTypePlaceholder')"
        :showAsterisk="true"
      />

      <div class="formBtnGroup">
        <b-button class="formBtn" @click="showDetailInformation"
          >{{
            $t('registerContainer.details')
          }}
        </b-button>
        <b-button class="formBtn" @click="isShowingTypeCamera = true"
          >{{$t('registerContainer.scan')}}</b-button
        >
      </div>

      <Input
        type="select"
        :label="$t('registerContainer.producer')"
        :tooltip="$t('registerContainer.producerDesc')"
        :isRequired="isManufacturerError"
        :optionValues="[...getManufacturers, { text: $t('unknown'), value: null }]"
        v-on:inputValue="onChangeManufacturer"
        :placeholder="$t('registerContainer.producerPlaceholder')"
        :showAsterisk="true"
      />

      <Input
        type="select"
        :label="$t('registerContainer.produced')"
        :tooltip="$t('registerContainer.producedDesc')"
        :isRequired="isProductionYearError"
        :optionValues="containerProductionYears"
        v-on:inputValue="onChangeProductionYear"
        :placeholder="$t('registerContainer.producedPlaceholder')"
        :showAsterisk="true"
        :value="productionYear"
      />

      <Input
        type="select"
        :label="$t('registerContainer.state')"
        :tooltip="$t('registerContainer.stateDesc')"
        :isRequired="isConditionError"
        :optionValues="getCondition"
        v-on:inputValue="onChangeState"
        :placeholder="$t('registerContainer.statePlaceholder')"
        :showAsterisk="true"
      />

      <Input
        type="date"
        :label="$t('registerContainer.certified')"
        :tooltip="$t('registerContainer.certifiedDesc')"
        @inputValue="onChangeDate"
        :isRequired="isDateError"
        style="border: 1px solid grey"
      />

<!--      <Input-->
<!--        type="select"-->
<!--        :label="$t('registerContainer.waste')"-->
<!--        :tooltip="$t('registerContainer.wasteDesc')"-->
<!--        :optionValues="[...getUseTypes, { text: 'Ingen', value: null }]"-->
<!--        @inputValue="useType = $event"-->
<!--        :placeholder="$t('registerContainer.wastePlaceholder')"-->
<!--      />-->

      <div>
        <div v-if="!showDimensionInputGroup" class="dimensions-input-group-header">
          <b-button
              class="formBtn"
              @click="showDimensionInputGroup = true"
          >
            {{ $t("registerContainer.showDimensionInputGroup") }}
          </b-button>
        </div>

        <div v-else class="dimensions-input-group">
          <Input
              type="text-number"
              :label="$t('registerContainer.length')"
              :tooltip="$t('registerContainer.lengthDesc')"
              @inputValue="externalLength = $event"
              :value="null"
              :isRequired="isExternalLengthError"
              error-message="Invalid number"
          />

          <Input
              type="text-number"
              :label="$t('registerContainer.width')"
              :tooltip="$t('registerContainer.widthDesc')"
              @inputValue="externalWidth = $event"
              :value="null"
              :isRequired="isExternalWidthError"
              error-message="Invalid number"
          />

          <Input
              type="text-number"
              :label="$t('registerContainer.height')"
              :tooltip="$t('registerContainer.heightDesc')"
              @inputValue="externalHeight = $event"
              :value="null"
              :isRequired="isExternalHeightError"
              error-message="Invalid number"
          />
        </div>
      </div>

      <Input
        type="text"
        :label="$t('registerContainer.notes')"
        :tooltip="$t('registerContainer.notesDesc')"
        v-on:inputValue="writtenNotes = $event"
        value=""
      />

      <div class="mainErrorTextMessage" v-if="showError">
        {{$t('registerContainer.required')}}
      </div>

      <div>
        <Button @clicked="postContainer" />
        <Cancel />
      </div>
    </b-container>
    <Geolocation v-on:submit="location = $event"></Geolocation>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { updateNewContainer } from "../../../services/reenapi";

import { BIconTrash, BIconCpu } from "bootstrap-vue";

import Geolocation from "../../../components/location/Geolocation";
import ContainerDetails from "../../../components/popups/ContainerDetails";
import Camera from "../../../components/camera/CameraComponent";

export default {
  components: {
    BIconTrash,
    BIconCpu,
    Geolocation,
    ContainerDetails,
    Camera,
  },
  data() {
    return {
      currentMountFilter: null,
      manufacturer: "",
      manufacturerSerialNo: null,
      productionYear: 0,
      lastCertified: null,
      writtenNotes: "",
      condition: "",
      useType: "",
      containerType: undefined,

      showDimensionInputGroup: false,
      externalLength: undefined,
      externalWidth: undefined,
      externalHeight: undefined,

      isShowingTypeCamera: false,
      showDetails: false,
      isTypeError: false,
      isProductionYearError: false,
      isConditionError: false,
      isLastCertifiedError: false,
      isManufacturerError: false,
      isStackableError: false,
      isDateError: false,
      isExternalLengthError: false,
      isExternalWidthError: false,
      isExternalHeightError: false,
      showError: false,
      isError: false,
      cameraError: false,
    };
  },

  computed: {
    ...mapGetters([
      "getScannedQrContainer",
      "getContainerTypes",
      "getMountTypes",
      "getCondition",
      "getScannedExistingUnit",
      "getUseTypes",
      "getChosenOrganization",
      "getChosenRegion",
      "getManufacturers",
    ]),

    containerProductionYears() {
      let years = [];

      let nullObj = {
        text: "Ukjent",
        value: 0,
      };
      years.push(nullObj);

      let latestYear = new Date(Date.now()).getFullYear();

      for (let i = 2000; i <= latestYear; i++) {
        let returnObj = {
          text: i,
          value: i,
        };
        years.push(returnObj);
      }
      return years.reverse();
    },

    createNewContainer() {
      let newContainer = {
        container_type: { id: this.containerType.id },
        alias: this.getScannedQrContainer + " " + this.containerType.name,
        serial_number: this.getScannedExistingUnit.serial_number, // TODO: Skal settes til produsentens serienummer
        age: this.productionYear,
        state: this.condition,
        use_type: this.useType,
        qr_code: this.getScannedQrContainer,
        manufacturer: this.manufacturer,
        last_certified: this.lastCertified, // TODO: Bør lese fra NG. År og måned. Komprimerende utstyr = "sist service"
        location: {
          latitude: this.location.coords.latitude,
          longitude: this.location.coords.longitude,
          timestamp: new Date(),
          signal_source: "gps",
        },
        organization: {
          id: this.getChosenOrganization.id,
          name: this.getChosenOrganization.name,
        },
        region: this.getChosenRegion ? { id: this.getChosenRegion.id } : null,
        unit: this.getScannedExistingUnit,
        notes: this.writtenNotes,
        installation_date: new Date(),
        tenant_id: this.getChosenOrganization.tenant_id,
        external_length: this.externalLength,
        external_width: this.externalWidth,
        external_height: this.externalHeight,
      };

      this.setNewContainer(newContainer);
      return newContainer;
    },

    filterContainerTypes() {
      let result;
      const filteredTypes = this.getContainerTypes.filter(type => type.value.mount_type == this.currentMountFilter);
      this.currentMountFilter === null ? result = this.getContainerTypes : result = filteredTypes;
      return result;
    },
  },

  methods: {
    ...mapActions([
      "setNewContainer",
      "setScannedExistingContainer",
      "setScannedExistingUnit",
    ]),

    async scanContainerType(containertype) {
      if (containertype === undefined) {
        this.cameraError = true;
        this.isShowingTypeCamera = false;
      } else {
        let specificType = this.getContainerTypes.filter(
          (ct) => ct.text === containertype
        );
        this.containerType = specificType[0].value;
      }
      this.isShowingTypeCamera = false;
    },

    showDetailInformation() {
      if (this.containerType == "") {
        this.showDetails = false;
        alert("Vennligst velg en containertype.");
      } else {
        this.showDetails = !this.showDetails;
      }
    },

    // Methods for adding value in container-object and toggle the validator.


    onChangeContainerType(event) {
      this.containerType = event;
      if (this.isTypeError === true) {
        this.isTypeError = false;
      }
      this.removeMainErrorMessage();
    },

    onChangeProductionYear(event) {
      this.productionYear = event;
      if (this.isProductionYearError === true) {
        this.isProductionYearError = false;
      }
      this.removeMainErrorMessage();
    },

    onChangeState(event) {
      this.condition = event;
      if (this.isConditionError === true) {
        this.isConditionError = false;
      }
      this.removeMainErrorMessage();
    },

    onChangeDate(event) {
      this.lastCertified = event;
      if (this.isDateError === true) {
        this.isDateError = false;
      }
      this.removeMainErrorMessage();
    },

    onChangeManufacturer(manufacturerId) {
      if (manufacturerId !== null) {
        this.manufacturer = this.getManufacturers.filter((manufacturer) => {
          return manufacturer.id === manufacturerId;
        })[0];
      } else {
        this.manufacturer = null;
      }

      if (this.isManufacturerError === true) {
        this.isManufacturerError = false;
      }
      this.removeMainErrorMessage();
    },

    checkInvalidFields() {
      this.containerType === undefined
        ? (this.isTypeError = true)
        : (this.isTypeError = false);
      this.productionYear === null
        ? (this.isProductionYearError = true)
        : (this.isProductionYearError = false);
      this.condition === ""
        ? (this.isConditionError = true)
        : (this.isConditionError = false);
      this.manufacturer === ""
        ? (this.isManufacturerError = true)
        : (this.isManufacturerError = false);

      this.isExternalLengthError = (this.externalLength !== undefined && isNaN(this.externalLength)) || this.externalLength < 0;
      this.isExternalWidthError = (this.externalWidth !== undefined && isNaN(this.externalWidth)) || this.externalWidth < 0;
      this.isExternalHeightError = (this.externalHeight !== undefined && isNaN(this.externalHeight)) || this.externalHeight < 0;
    },

    removeMainErrorMessage() {
      if (
        this.isTypeError === false &&
        this.isProductionYearError === false &&
        this.isConditionError === false &&
        this.isManufacturerError === false
      ) {
        this.showError = false;
      }
    },

    async postContainer() {
      this.checkInvalidFields();

      if (
        !this.isTypeError &&
        !this.isProductionYearError &&
        !this.isConditionError &&
        !this.isManufacturerError &&
        !this.isExternalLengthError &&
        !this.isExternalWidthError &&
        !this.isExternalHeightError
      ) {
        let response = await updateNewContainer(this.createNewContainer);
        if (response === null) {
          return;
        }

        this.setNewContainer({});
        this.setScannedExistingContainer({});
        this.setScannedExistingUnit({});
        this.$router.push("submitsuccess");
      } else {
        this.showError = true;
      }
    },
  },

  sortContainerTypes() {
    let uniqueContainers = [];

    for (var item of this.getContainerTypes) {
      if (!uniqueContainers.includes(item.text.trim())) {
        uniqueContainers.push(item.text.trim());
      }
      this.containerTypes = uniqueContainers.sort();
    }
    return item;
  },
};
</script>

<style scoped>
.mainErrorTextMessage {
  color: #dc3545;
  font-size: 16px;
  margin-top: 20px;
}
.detailCard {
  z-index: 1000;
}

.showDetails,
.link,
.bi-question-circle {
  cursor: pointer;
}
.cameraBtn {
  text-align: center;
  border-radius: 5px;
  width: 224px;
  font-size: 1rem;
  color: #ffe893;
  border: solid #ffe893 2px;
  background-color: transparent;
  margin-bottom: 10px;
  margin-top: 20px;
}
.formBtn {
  text-align: center;
  border-radius: 5px;
  min-width: 100px;
  font-size: 1rem;
  color: #ffe893;
  border: solid #ffe893 2px;
  background-color: transparent;
}
.formBtnGroup {
  display: flex;
  justify-content: space-between;
  width: 224px;
  margin: auto;
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding-bottom: 20px;
}
.headline {
  display: flex;
  justify-content: space-between;
  width: 224px;
  margin: auto;
  font-weight: bold;
  letter-spacing: 0.1rem;
}
.inputRadioStyle {
  padding-top: 10px;
  width: 224px;
  display: flex;
  justify-content: space-evenly;
  margin: auto;
}
.inputStyle {
  padding: 10px;
}

.dimensions-input-group-header {
  padding: 1em;
}

.dimensions-input-group {
  border: 1px solid grey;
  margin-top: 10px;
}
</style>
