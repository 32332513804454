<template>
  <div :style="data.subStyle">
    <svg
      class="svgContainer"
      :width="data.width"
      :height="data.height"
      :viewBox="data.viewBox"
      :style="data.style"
    >
      <path
        :d="path"
        :fill="data.color"
        :fillRule="data.fillRule"
        v-for="path in data.paths"
        :key="path"
      />
      <circle
        v-for="circle in data.circles"
        :key="circle.key"
        :cx="circle.cx"
        :cy="circle.cy"
        :r="circle.r"
        :fill="data.color"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
};
</script>

<style scoped>
.svgContainer {
  padding-top: 3px;
}
</style>