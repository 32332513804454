<template>
  <div>
    <button
      class="baseButton"
      :class="type === 'backButton' ? 'backButton' : ''"
      @click="$emit('clicked', $event)"
    >
      {{ label }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Neste",
    },
    type: {
      type: String,
      default: "baseButton",
    },
  },
};
</script>

<style scoped>
.baseButton {
  position: relative;
  border-radius: 25px;
  width: 150px;
  padding: 10px;
  margin-top: 15px;
  font-size: 1.1rem;
  color: #ffe893;
  border: solid #ffe893 3px;
  background-color: transparent;
}

.backButton {
  border-color: #ff6a6a;
  color: #ff6a6a;
}
</style>
