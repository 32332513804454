const state = {
    scannedQrContainer: "",
    scannedBox: {},
    scannedUnitToBox: {},
    scannedExistingContainer: {},
    scannedExistingUnit: {},
    scannedExistingTriplog: {},
}

const getters = {
    getScannedQrContainer: (state) => state.scannedQrContainer,
    getScannedBox: (state) => state.scannedBox,
    getScannedUnitToBox: (state) => state.scannedUnitToBox,
    getScannedExistingContainer: (state) => state.scannedExistingContainer,
    getScannedExistingUnit: (state) => state.scannedExistingUnit,
    getScannedExistingTriplog: (state) => state.scannedExistingTriplog,
}

const actions = {
    setScannedQrContainer({ commit }, qr) {
        commit("mutateScannedQrContainer", qr)
    },
    setScannedBox({ commit }, box) {
        commit("mutateScannedBox", box);
    },
    setScannedUnitToBox({ commit }, unitInBox) {
        commit("mutateScannedUnitToBox", unitInBox);
    },
    setScannedExistingContainer({ commit }, container) {
        commit("mutateScannedExistingContainer", container)
    },
    setScannedExistingUnit({ commit }, unit) {
        commit("mutateScannedExistingUnit", unit)
    },
    setScannedExistingTriplog({ commit }, triplog) {
        commit("mutateScannedExistingTriplog", triplog);
    },
}

const mutations = {
    mutateScannedQrContainer: (state, scannedQrContainer) => (state.scannedQrContainer = scannedQrContainer),
    mutateScannedBox: (state, scannedBox) => (state.scannedBox = scannedBox),
    mutateScannedUnitToBox: (state, scannedUnitToBox) => (state.scannedUnitToBox = scannedUnitToBox),
    mutateScannedExistingContainer: (state, scannedExistingContainer) => (state.scannedExistingContainer = scannedExistingContainer),
    mutateScannedExistingUnit: (state, scannedExistingUnit) => (state.scannedExistingUnit = scannedExistingUnit),
    mutateScannedExistingTriplog: (state, scannedExistingTriplog) => (state.scannedExistingTriplog = scannedExistingTriplog),
}

export default {
    state,
    getters,
    actions,
    mutations,
};