<template>
  <div>
    <b-container fluid="xs">
      <SelectLocale></SelectLocale>
      <h1>ONBOARDING</h1>
      <span>{{ $t("main.desc") }}</span>

      <Input
        :label="$t('main.pickOrg')"
        type="select"
        :optionValues="getOrganizations"
        :showAsterisk="true"
        :tooltip="$t('main.orgDesc')"
        @inputValue="setChosenOrganization"
        :value="getChosenOrganization ? getChosenOrganization.value : undefined"
        :placeholder="$t('main.pickOrg')"
      />

      <Input
        :label="$t('main.pickRegion')"
        type="select"
        :optionValues="getRegions"
        :showAsterisk="true"
        :tooltip="$t('main.regionDesc')"
        @inputValue="setChosenRegion"
        :value="getChosenRegion ? getChosenRegion.value : undefined"
        :placeholder="$t('main.pickRegion')"
      />

      <div class="formBtnGroup">
        <b-button class="formBtn" @click="$router.push('howtouse')"
          >{{ $t("main.userMan") }}
        </b-button>
        <b-button class="formBtn" @click="logout">
          {{ $t("main.logout") }}</b-button
        >
      </div>

      <!-- <div>
        <button @click="logStuff">Log stuff</button>
        <button @click="$router.push('registernewdevice')">Test Sensor Scan</button>
      </div> -->

      <div class="menuButtons" v-if="showMenu">
        <b-col cols="12">
          <div class="box" @click="$router.push('registernewdevice')">
            {{ $t("main.register") }}
          </div>
        </b-col>

        <b-col cols="12">
          <div class="box" @click="$router.push('swapqrcamera')">
            {{ $t("main.swap") }}
          </div>
        </b-col>

          <b-col cols="12">
            <div class="box" @click="$router.push('scanbox')" v-show="getChosenOrganization.tenant_id !=
            '37999d93-6b9a-47cb-85c3-289147055a47'">
              {{ $t("driverKit.title") }}
            </div>
          </b-col>

        <div
          v-show="
            getChosenOrganization.tenant_id ===
            '37999d93-6b9a-47cb-85c3-289147055a47'
          "
        >
          <b-col cols="12">
            <div class="box" @click="createBlankBox">
              {{ $t("main.newBox") }}
            </div>
          </b-col>

          <b-col cols="12">
            <div class="box" @click="$router.push('scanbox')">
              {{ $t("main.boxMaintenance") }}
            </div>
          </b-col>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SelectLocale from "../components/inputs/SelectLocale.vue";
import { postBox } from "../services/reenapi";
// import { getCustomer } from "../services/enevoapi";

export default {
  name: "HomePage",
  components: {
    SelectLocale,
  },
  data() {
    return {
      blankBox: {
        tenant_id: "37999d93-6b9a-47cb-85c3-289147055a47",
        qr_code: "",
        serial_number: "",
        units: [],
        timestamp: new Date()
      },
    };
  },
  computed: {
    ...mapGetters([
      "getOrganizations",
      "getRegions",
      "getChosenRegion",
      "getChosenOrganization",
    ]),

    showMenu() {
      let isShowingMenu = false;

      if (this.getChosenOrganization != null && this.getRegions.length === 0) {
        isShowingMenu = true;
      } else if (
        this.getChosenOrganization != null &&
        this.getChosenRegion != null
      ) {
        isShowingMenu = true;
      }
      return isShowingMenu;
    },
  },

  methods: {
    ...mapActions(["setChosenOrganization", "setChosenRegion", "setBoxes"]),
    
    // async logStuff() {
    //   // let sensor = await getSensor("103177100000015");
    //   let customer = await getCustomer();
    //   console.log(customer);
    // },

    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },

    async createBlankBox() {
      await postBox(this.blankBox);
      await this.setBoxes();
      this.$router.push("registernewbox");
    },
  },
};
</script>

<style scoped>
.box {
  transition: 0.4s;
  border: 1px solid #f7f8f8;
  background-color: rgb(0, 0, 0, 0.7);
  margin: 5%;
  margin-bottom: 8%;
  padding: 5%;
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 1.125rem;
}

.box:hover {
  transform: scale(1.05);
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.25);
}
.formBtn {
  text-align: center;
  border-radius: 5px;
  width: 224px;
  font-size: 1rem;
  color: #ffe893;
  border: solid #ffe893 2px;
  background-color: transparent;
  margin-bottom: 20px;
}
.formBtnGroup {
  display: flex;
  flex-direction: column;
  width: 224px;
  margin: auto;
  font-weight: bold;
  letter-spacing: 0.1rem;
}
.menuButtons {
  padding-bottom: 25px;
}
</style>
