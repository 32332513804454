<template>
  <div id="app">
    <div v-if="!$auth.loading && !$auth.isAuthenticated">
      <Login />
    </div>
    <div v-else>
      <Logo />
      <router-view class="view" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Login from "./components/login/Login";
import Logo from "./components/LogoHeader.vue";

export default {
  name: "App",
  components: {
    Login,
    Logo,
  },
  methods: {
    ...mapActions([
      "setContainerTypes",
      "setMountTypes",
      "setCondition",
      "setUseTypes",
      "setOrganizations",
      "setManufacturers",
      "setBoxes",
      "setSwapCauses",
    ]),
  },
  async mounted() {
    await this.setOrganizations();
    await this.setBoxes();
    await this.setContainerTypes();
    await this.setCondition();
    await this.setMountTypes();
    await this.setUseTypes();
    await this.setManufacturers();
    await this.setSwapCauses();
  },
};
</script>

<style>
@import "./assets/fonts/SofiaPro.css";

html {
  background-color: black;
  overflow: hidden;
}
#app {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  font-family: "Sofia Pro", sans-serif;
  text-align: center;
  color: #f7f8f8;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
}
.view {
  height: 100vh !important;
}
.container {
  background-color: rgb(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  position: relative;
  padding: 15px;
  padding-bottom: 200px;
}
h1 {
  color: #ffe893;
  padding: 2%;
}
.form-control {
  width: 224px !important;
  margin: auto;
  background-color: black !important;
}
.col {
  width: 224px;
  padding: 20px;
  text-align: center;
}
input,
.description,
.custom-select {
  background-color: rgb(0, 0, 0, 0.7) !important;
  color: white !important;
  border: 1px solid white;
  width: 224px !important;
}
</style>
