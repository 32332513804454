<template>
  <div>
    <Camera
      v-if="isShowingCamera && newSerialNumber == ''"
      v-on:scanned="scanUnit"
      itemThatShouldBeScanned="unit"
      :cancelAction="cancelBox"
      :invalidCode="invalidSerialNumber"
    />
    <Camera
      v-if="isShowingQrCamera"
      v-on:scanned="scanQrBox"
      itemThatShouldBeScanned="box"
      :cancelAction="cancelBox"
      :invalidCode="wrongBoxFormat"
    />
    <b-container>
      <h1>{{ $t("registerNewBox.title") }}</h1>

      <b-row>
        <b-col>
          <span>{{ $t("registerNewBox.numbersInBox") }}</span>
          <h3>{{ numberOfUnits }}</h3>
        </b-col>
      </b-row>

      <div v-if="isBoxEmpty">
        <h3>{{ $t("registerNewBox.empty") }}</h3>
      </div>

      <div class="listDiv" v-if="!isBoxEmpty">
        <h3>{{ $t("registerNewBox.unitsInBox") }}</h3>
        <ul>
          <li v-for="(item, index) in addedUnitsList" :key="index">
            {{ item }}
          </li>
        </ul>
      </div>

      <div v-if="isShowingSerialNumber">
        <Input
          type="text"
          :label="$t('registerNewBox.qr')"
          :tooltip="$t('registerNewBox.qrTooltip')"
          :isDisabled="true"
          :value="qrCode"
        />
      </div>

      <div class="scanBtn">
        <button
          v-if="!isShowingSerialNumber"
          class="boxBtn"
          @click="startProcess"
        >
          {{ $t("registerNewBox.addUnit") }}
        </button>
      </div>

      <div class="scanBtn" v-if="!isBoxEmpty && hasSerialNumber">
        <Button
          :label="$t('registerNewBox.addSerial')"
          @clicked="isShowingQrCamera = true"
        />
      </div>
      <div v-if="!isBoxEmpty && !hasSerialNumber">
        <Button :label="$t('registerNewBox.saveBox')" @clicked="saveBox" />
      </div>
      <Cancel :clicked="cancelBox" />
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Camera from "../../components/camera/CameraComponent.vue";
import { isValidUnitCode, isValidQrCode } from "../../utils/validators";
import { getUnit, putUnit, putBox, deleteBox } from "../../services/reenapi";

export default {
  components: {
    Camera,
  },
  data() {
    return {
      isBoxEmpty: true,
      isShowingCamera: false,
      isShowingQrCamera: false,
      isShowingSerialNumber: false,
      hasSerialNumber: false,
      wrongBoxFormat: false,
      invalidSerialNumber: false,
      newSerialNumber: "",
      numberOfUnits: 0,
      addedUnitsList: [],
      fetchSerialNumber: "",
      qrCode: "",
    };
  },
  computed: {
    ...mapGetters(["getNewBox", "getBoxes", "getScannedUnitToBox"]),

    createNewBox() {
      let newBox = {
        name: this.getNewBox.name,
        qr_code: this.qrCode,
        serial_number: this.newSerialNumber,
        id: this.getNewBox.id,
      };
      this.setNewBox(newBox);
      return newBox;
    },
  },
  methods: {
    ...mapActions(["setNewBox", "setScannedUnitToBox"]),

    startProcess() {
      let boxes = this.getBoxes;
      let list = [];

      for (var i = 0; i < boxes.length; i++) {
        if (this.newSerialNumber === boxes[i].serial_number) {
          list.push(boxes[i]);
        }
      }
      if (list[0].serial_number === "") {
        this.fetchSerialNumber = list[0].serial_number;
        this.setNewBox(list[0]);
      }
      this.isShowingCamera = true;
    },

    async scanUnit(scannedUnit) {
      if (scannedUnit === "") return;

      if (isValidUnitCode(scannedUnit)) {
        let response = await getUnit(scannedUnit);
        let unit;
        if (response.data.length > 0) {
          unit = response.data[0];
        }

        if (
          response.status === 200 &&
          !this.addedUnitsList.includes(unit.serial_number)
        ) {
          this.setScannedUnitToBox(unit);
          this.getScannedUnitToBox.box_id = this.getNewBox.id;
          this.addedUnitsList.push(unit.serial_number);
          this.numberOfUnits++;
          this.isBoxEmpty = false;
          this.newSerialNumber != ""
            ? (this.hasSerialNumber = false)
            : (this.hasSerialNumber = true);
        } else {
          this.invalidSerialNumber = true;
        }
        await putUnit(this.getScannedUnitToBox);
        this.invalidSerialNumber = false;
        this.isShowingCamera = false;
      } else {
        this.invalidSerialNumber = true;
      }
    },

    async scanQrBox(qr) {
      if (qr === "") return;

      if (!isValidQrCode(qr)) {
        this.wrongBoxFormat = true;
        return;
      }

      if (isValidQrCode(qr)) {
        this.qrCode = qr;
        this.isShowingSerialNumber = !this.isShowingSerialNumber;

        // Year
        let year = new Date().getFullYear();
        let yearTwoDigits = year.toString().substr(-2);

        // Number of units
        let unitsNumber = this.addedUnitsList.length;
        if (unitsNumber < 10) {
          unitsNumber = "0" + unitsNumber;
        }

        // Box number
        let currentAmount = this.getBoxes.length;
        currentAmount++;
        let boxNumber = ("0000" + currentAmount).slice(-4);
        this.newSerialNumber = "BX" + yearTwoDigits + unitsNumber + boxNumber;
        this.hasSerialNumber = !this.hasSerialNumber;
        this.isShowingQrCamera = false;
      }
    },

    async saveBox() {
      await putBox(this.createNewBox);
      this.setNewBox({});
      this.setScannedUnitToBox({});
      this.$router.push("boxsuccess");
    },

    async cancelBox() {
      let boxes = this.getBoxes;
      let list = [];

      for (var i = 0; i < boxes.length; i++) {
        if (
          this.newSerialNumber === boxes[i].serial_number ||
          boxes[i].serial_number === ""
        ) {
          list.push(boxes[i]);
        }
      }
      if (list[0].serial_number === "") {
        this.fetchSerialNumber = list[0].serial_number;
        this.setNewBox(list[0]);
      }
      await deleteBox(this.getNewBox);
    },
  },
};
</script>

<style>
.boxBtn {
  text-align: center;
  border-radius: 5px;
  width: 224px;
  font-size: 1rem;
  color: #ffe893;
  border: solid #ffe893 2px;
  background-color: transparent;
  padding: 6px;
  margin-top: 5px;
}
.scanBtn {
  margin-top: 50px;
}
.addUnits {
  margin-top: 20px;
}
.listDiv {
  padding-top: 10px;
  width: 224px;
  margin: auto;
}
li {
  text-align: left;
}
</style>
