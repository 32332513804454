<template>
  <!-- TODO: Flytte denne filen til et annet sted enn views? Tenker den er kjekk å ha -->
  <div>

    <FormComponent :formData="sendData" />

    <!-- <FormComponent :formData="sendData" /> -->

    <Input
      type="text"
      label="TEXT"
      tooltip="This is a tooltip."
      :isRequired="checkTextError"
      v-on:inputValue="textValue = $event"
    />
    <Input
      type="number"
      label="NUMBER"
      tooltip="This is a tooltip."
      :isRequired="checkNumberError"
      :showAsterisk="true"
      v-on:inputValue="numberValue = $event"
    />
    <Input
      type="select"
      label="SELECT"
      tooltip="This is a tooltip."
      :isRequired="checkSelectError"
      :optionValues="options"
      :showAsterisk="true"
      v-on:inputValue="selectValue = $event"
    />
    <Input
      type="text"
      label="DISABLED"
      tooltip="This is a tooltip."
      :isDisabled="true"
      :isRequired="checkDisabledError"
      :value="disabledValue"
    />
    <Input
      type="text"
      label="REQUIRED"
      tooltip="This is a tooltip."
      :isDisabled="false"
      :isRequired="checkRequiredError"
      :showAsterisk="true"
      v-on:inputValue="requiredValue = $event"
    />

    <!-- <Input
      type="date"
      label="DATE"
      tooltip="This is a tooltip"
      :isRequired="checkDateError"
      :showAsterisk="true"
      v-on:inputValue="dateValue = $event"
    /> -->

    <DatePicker
      label="Sist sertifisert TEST"
      tooltip="This is a tooltip."
      :isRequired="checkDateError"
      v-on:yearValue="year = $event"
      v-on:monthValue="month = $event"
      :showAsterisk="true"
    />

    <Button />
    <Cancel />
    <br />
    <button @click="validateInput">Check Validator</button>
  </div>
</template>

<script>
import DatePicker from "../components/inputs/DatePickerComponent.vue";
import FormComponent from "../components/inputs/FormComponent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    DatePicker,
    FormComponent,
  },
  data() {
    return {
      textValue: "",
      numberValue: "",
      selectValue: "",
      disabledValue: "",
      requiredValue: "",
      year: 0,
      month: 0,
      dateValue: this.year + " " + this.month,
      checkTextError: false,
      checkNumberError: false,
      checkSelectError: false,
      checkDisabledError: false,
      checkRequiredError: false,
      checkDateError: false,
      options: ["Hei", "På", "Deg", "Pia", "Rikard"],
    };
  },
  computed: {
    ...mapGetters(["getManufacturers"]),

    sendData() {
      let formData = [
        {
          name: "producer",
          type: "select",
          label: "TEST",
          tooltip: this.$t('registerContainer.producerDesc'),
          required: false,
          asterisk: true,
          placeholder: this.$t('registerContainer.producerPlaceholder'),
          options: [...this.getManufacturers, { text: this.$t('unknown'), value: null }],
          value: undefined
        },{
          name: "formi",
          type: "text",
          label: "FORM",
          tooltip: "YES",
          required: true,
          asterisk: true,
          value: "hei"
        },
      ]
      return formData;
    }
  },
  methods: {
    validateInput() {
      this.textValue === ""
        ? (this.checkTextError = true)
        : (this.checkTextError = false);
      this.numberValue === ""
        ? (this.checkNumberError = true)
        : (this.checkNumberError = false);
      this.selectValue === ""
        ? (this.checkSelectError = true)
        : (this.checkSelectError = false);
      this.disabledValue === ""
        ? (this.checkDisabledError = true)
        : (this.checkDisabledError = false);
      this.requiredValue === ""
        ? (this.checkRequiredError = true)
        : (this.checkRequiredError = false);
      this.year || this.month === 0
        ? (this.checkDateError = true)
        : (this.checkDateError = false);
      // this.dateValue === null ? this.checkDateError = true : this.checkDateError = false;
    },
  },
};
</script>

<style scoped>
</style>