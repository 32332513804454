<template>
  <div class="background">
    <b-container>
      <span @click="$router.push('/')">{{ $t("howTo.back") }}n</span>
      <h1 class="mainHeadline">{{ $t("howTo.title") }}</h1>
      <b-row>
        <b-col>
          <div class="divider"></div>
          <span>
            {{ $t("howTo.textMain") }}
          </span>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h2 v-b-toggle.newUnit>{{ $t("howTo.newUnit") }}</h2>
        </b-col>
      </b-row>

      <b-collapse id="newUnit">
        <ul>
          <div v-for="(text, index) in newUnitGuide" :key="index">
            <li>
              <b
                ><span class="headline">{{ text.headline }}</span></b
              >
              <br />
              <div class="divider"></div>
              <span>{{ text.info }}</span>
            </li>
          </div>
        </ul>
      </b-collapse>

      <div class="divider"></div>

      <b-row>
        <b-col>
          <h2 v-b-toggle.newVehicle>{{$t('howTo.newVehicle')}}</h2>
        </b-col>
      </b-row>

      <b-collapse id="newVehicle">
        <ul>
          <div v-for="(text, index) in newVehicleGuide" :key="index">
            <li>
              <b
                ><span class="headline">{{ text.headline }}</span></b
              >
              <br />
              <div class="divider"></div>
              <span>{{ text.info }}</span>
            </li>
          </div>
        </ul>
      </b-collapse>

      <div class="divider"></div>

      <b-row>
        <b-col>
          <h2 v-b-toggle.swapGuide>{{$t('howTo.swap')}}</h2>
        </b-col>
      </b-row>

      <b-collapse id="swapGuide">
        <ul>
          <div v-for="(text, index) in swapGuide" :key="index">
            <li>
              <b
                ><span class="headline">{{ text.headline }}</span></b
              >
              <br />
              <div class="divider"></div>
              <span>{{ text.info }}</span>
            </li>
          </div>
        </ul>
      </b-collapse>

      <div class="divider"></div>

      <b-row>
        <b-col>
          <h2 v-b-toggle.maintenanceGuide>{{$t('howTo.maintenance')}}</h2>
        </b-col>
      </b-row>

      <b-collapse id="maintenanceGuide">
        <ul>
          <span>{{$t('howTo.maintenanceDesc')}}</span>
          <div v-for="(text, index) in maintenanceGuide" :key="index">
            <li>
              <b
                ><span class="headline">{{ text.headline }}</span></b
              >
              <br />
              <div class="divider"></div>
              <span>{{ text.info }}</span>
            </li>
          </div>
        </ul>
      </b-collapse>

      <div class="divider"></div>

      <b-row>
        <b-col>
          <h2 v-b-toggle.contactUs>{{$t('howTo.contact')}}</h2>
        </b-col>
      </b-row>

      <b-collapse visible id="contactUs">
        <b-row>
          <b-col>
            <span>REEN AS</span>
            <br />
            <span>Hammergata 24</span>
            <br />
            <span>3264 Larvik</span>
          </b-col>
          <b-col>
            <span>+47 33 51 05 00</span>
            <br />
            <span>support@reen.com</span>
          </b-col>
        </b-row>
      </b-collapse>
    </b-container>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    newUnitGuide() {
      return this.$t("howTo.unitGuide");
    },
    newVehicleGuide() {
      return this.$t("howTo.vehicleGuide");
    },
    swapGuide() {
      return this.$t("howTo.swapGuide")
    },
    maintenanceGuide() {
      return this.$t('howTo.maintenanceGuide')
    },
  },
};
</script>

<style scoped>
h2 {
  color: #ffe893;
}

ul {
  padding-left: 0;
}

li {
  list-style-type: none;
  font-size: 1rem;
  text-align: left;
  padding: 10px;
}

.mainHeadline {
  padding-bottom: 0;
}

.headline {
  color: #ffe893;
  margin: 10px 0px -20px 0px;
  display: flex;
  justify-content: flex-start;
}

.divider {
  border-bottom: 1px solid #ffe893;
  margin-bottom: 10px;
  margin-left: 0px;
}
</style>
