<template>
  <div class="detailCard">
    <Geolocation v-on:submit="location = $event"></Geolocation>
    <b-container>
      <div class="topline">
        <h4>{{ $t("manualSwap.typeManually") }}</h4>
        <div @click="$emit('closeDetails')">
          <Icon class="exitCross" :data="closeIcon" />
        </div>
      </div>
      <b-row>
        <b-col>
          <div>
            {{ $t("manualSwap.desc") }}
          </div>
          <Input
            type="text"
            label="QR"
            :tooltip="$t('manualSwap.oldQr')"
            :value="newQrCode"
            v-on:inputValue="newQrCode = $event.toUpperCase()"
            :showAsterisk="true"
          />
          <b-row>
            <b-col v-show="showError">
              <div class="mainErrorTextMessage">
                {{ $t("manualSwap.wrongQr") }}
              </div>
            </b-col>
          </b-row>
          <Button :label="$t('manualSwap.nextButton')" @clicked="getContainerFromQr" />
        </b-col>
      </b-row>
      <div class="divider"></div>
      <div class="topline">
        <h4>{{$t('manualSwap.qrGone')}}</h4>
      </div>
      <div>
        {{$t('manualSwap.qrGoneDesc')}}
      </div>
      <Button
        :label="$t('manualSwap.register')"
        @clicked="$router.push('registernewdevice')"
      />
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Icon from "@/components/icons/Icons.vue";
import { Close } from "../icons/icons";
import { getContainer, putContainer } from "../../services/reenapi";
import Geolocation from "@/components/location/Geolocation";

export default {
  components: {
    Icon,
    Geolocation
  },
  data() {
    return {
      closeIcon: Close("white", 35, {}),
      newQrCode: "",
      showError: false,
    };
  },
  computed: {
    ...mapGetters(["getCurrentTheme"]),
  },
  methods: {
    ...mapActions(["setScannedExistingContainer"]),

    async getContainerFromQr() {
      let response = await getContainer(this.newQrCode);

      if (
        response.status === 200 &&
        response.data.length > 0 &&
        response.data[0].qr_code === this.newQrCode
      ) {
        let container = response.data[0];
        let newLocation = {
          latitude: this.location.coords.latitude,
          longitude: this.location.coords.longitude,
          timestamp: new Date(),
          signal_source: "gps",
        }
        container.location = newLocation;
        await putContainer(container);
        this.setScannedExistingContainer(container);
        this.$router.push("maintenance");
      } else {
        this.showError = true;
      }
    },
  },
};
</script>

<style scoped>
.detailCard {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}

.container {
  background-color: black;
  border: 2px solid #f7f8f8;
  border-radius: 20px;
  position: absolute;
  font-size: 1.2rem;
  z-index: 999;
  overflow: auto;
  width: 80%;
  height: 70%;
  top: 10%;
}

.topline {
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  color: #ffe893;
  padding: 5px;
  margin-bottom: 20px;
}
.exitCross {
  position: relative;
  top: -5px;
  float: right;
  cursor: pointer;
  color: #ffe893 !important;
}
.mainErrorTextMessage {
  color: #dc3545;
  font-size: 16px;
  text-align: center;
}
.divider {
  border-bottom: 1px solid #ffe893;
  margin-bottom: 10px;
  margin-left: 0px;
}
</style>
