<template>
  <div class="detailCard">
    <b-container>
      <div class="topline">
        <h4>{{ detailInfo.name }}</h4>
        <div @click="$emit('closeDetails')">
          <Icon class="exitCross" :data="closeIcon" />
        </div>
      </div>
      <div class="containerImg">
        <b-img class="image" :src="getImgUrl(detailInfo.name + '.png')"></b-img>
      </div>
      <b-row>
        <b-col>
          <ul>
            <div v-for="(item, index) in createDetailsList" :key="index">
              <li>
                <span class="text-color">{{ item.name }}</span>
                <b
                  ><span>{{ item.value }}</span></b
                >
              </li>
            </div>
          </ul>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Icon from "@/components/icons/Icons.vue";
import { mapGetters } from "vuex";
import { Close } from "../icons/icons";

export default {
  components: {
    Icon,
  },

  props: {
    detailInfo: Object,
  },

  data() {
    return {
      closeIcon: Close("white", 35, {}),
    };
  },

  methods: {
    getImgUrl(arg) {
      let containerImage =
        "https://reenstorageaccount.blob.core.windows.net/containerimages/" +
        arg;
      return containerImage;
    },
  },

  computed: {
    ...mapGetters(["getCurrentTheme"]),

    createDetailsList() {
      let containerType = this.detailInfo;

      return [
        {
          name: this.$t('containerDetails.volume'),
          value: containerType.volume + "m³",
        },
        {
          name: this.$t('containerDetails.mountType'),
          value: containerType.mount_type,
        },
        {
          name: this.$t('containerDetails.construction'),
          value: containerType.construction,
        },
        {
          name: this.$t('containerDetails.weight'),
          value: containerType.weight + "kg",
        },
      ];
    },
  },
};
</script>

<style scoped>
.detailCard {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}

.container {
  background-color: black;
  border: 2px solid #f7f8f8;
  border-radius: 20px;
  position: absolute;
  font-size: 1.2rem;
  z-index: 999;
  overflow: auto;
  width: 80%;
  height: 70%;
  top: 10%;
}

.topline {
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  color: #ffe893;
  padding: 5px;
  margin-bottom: 20px;
}
.exitCross {
  position: relative;
  top: -5px;
  float: right;
  cursor: pointer;
  color: #ffe893 !important;
}

ul {
  padding-left: 0;
}

.text-color {
  color: rgb(155, 155, 155);
}
.image {
  margin-bottom: 20px;
}

.containerImg {
  border-bottom: 1px solid #f7f8f8;
}

li {
  list-style-type: none;
  font-size: 1.1rem;
  line-height: 1.9;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 3px;
  text-align: right;
}
</style>
