<template>
  <div>
    <ManualSwap class="detailCard" v-if="isShowingManualWindow" />
    <Camera
      v-if="isShowingQrCamera"
      v-on:scanned="swapQrCode"
      itemThatShouldBeScanned="newQr"
      :invalidCode="wrongContainerFormat"
    />

    <b-container>
      <span @click="$router.push('/')">{{ $t("howTo.back") }}</span>
      <h1>{{ $t("maintenanceContainer.title") }}</h1>

      <div class="containerImg">
        <b-img
          class="image"
          :src="
            getImgUrl(getScannedExistingContainer.container_type.name + '.png')
          "
        ></b-img>
      </div>

      <div class="buttons">
        <b-button class="scanBtn" @click="isShowingQrCamera = true">
          {{ $t("maintenanceContainer.swap") }}
        </b-button>
        <b-button class="scanBtn" @click="$router.push('restorecontainer')">
          {{ $t("maintenanceContainer.restore") }}
        </b-button>
      </div>

      <Input
        type="select"
        :label="$t('registerContainer.state')"
        :tooltip="$t('maintenanceContainer.stateTooltip')"
        :optionValues="getCondition"
        :value="getScannedExistingContainer.state"
        v-on:inputValue="newState = $event"
      />

      <div class="saveGroup">
        <b-button class="saveBtn" @click="saveContainer"
          >{{ $t("maintenanceContainer.saveButton") }}
        </b-button>
        <div class="successText" v-show="isSaved">
          {{ $t("maintenanceContainer.saved") }}
        </div>
      </div>

      <div
        v-for="(item, index) in createContainerInformationObject"
        :key="index"
      >
        <Input
          type="text"
          :label="item.name"
          :tooltip="item.tooltip"
          :isDisabled="true"
          :value="item.value"
        />
      </div>
    </b-container>
  </div>
</template>

<script>
import Camera from "../../../components/camera/CameraComponent";
import { isValidQrCode } from "../../../utils/validators";
import { mapActions, mapGetters } from "vuex";
import ManualSwap from "../../../components/popups/ManualSwap.vue";
import { putContainer } from "../../../services/reenapi";

export default {
  components: {
    Camera,
    ManualSwap,
  },
  data() {
    return {
      isShowingQrCamera: false,
      isShowingRestoreCamera: false,
      isShowingManualWindow: false,
      newState: "",
      isSaved: false,
      wrongContainerFormat: false,
    };
  },
  computed: {
    ...mapGetters([
      "getScannedExistingContainer",
      "getCondition",
      "getScannedQrContainer"
    ]),

    createContainerInformationObject() {
      let existingContainer = this.getScannedExistingContainer;
      return [
        {
          name: this.$t("maintenanceContainer.containerType"),
          value: existingContainer.container_type.name,
          tooltip: this.$t("maintenanceContainer.containerTypeTooltip"),
        },
        {
          name: this.$t("maintenanceContainer.qr"),
          value: existingContainer.qr_code,
          tooltip: this.$t("maintenanceContainer.qrTooltip"),
        },
        {
          name: this.$t("maintenanceContainer.serial"),
          value: existingContainer.serial_number,
          tooltip: this.$t("maintenanceContainer.serialTooltip"),
        },
        {
          name: this.$t("maintenanceContainer.unit"),
          value:
            existingContainer.unit.name != null
              ? existingContainer.unit.name
              : existingContainer.unit.serial_number,
          tooltip: this.$t("maintenanceContainer.unitTooltip"),
        },
        {
          name: this.$t("maintenanceContainer.age"),
          value: existingContainer.age,
          tooltip: this.$t("maintenanceContainer.ageTooltip"),
        },
        {
          name: this.$t("maintenanceContainer.region"),
          value: existingContainer.region.name,
          tooltip: this.$t("maintenanceContainer.regionTooltip"),
        },
        {
          name: this.$t("maintenanceContainer.mountType"),
          value: existingContainer.container_type.mount_type,
          tooltip: this.$t("maintenanceContainer.mountTypeTooltip"),
        },
        {
          name: this.$t("maintenanceContainer.construction"),
          value: existingContainer.container_type.construction,
          tooltip: this.$t("maintenanceContainer.constructionTooltip"),
        },
        {
          name: this.$t("maintenanceContainer.stackable"),
          value:
            existingContainer.container_type.stackable === false
              ? this.$t("maintenanceContainer.no")
              : this.$t("maintenanceContainer.yes"),
          tooltip: this.$t("maintenanceContainer.stackableTooltip"),
        },
        {
          name: this.$t("maintenanceContainer.volume"),
          value: existingContainer.container_type.volume,
          tooltip: this.$t("maintenanceContainer.volumeTooltip"),
        },
        {
          name: this.$t("maintenanceContainer.weight"),
          value: existingContainer.container_type.weight,
          tooltip: this.$t("maintenanceContainer.weightTooltip"),
        },
        {
          name: this.$t("maintenanceContainer.notes"),
          value: existingContainer.notes,
          tooltip: this.$t("maintenanceContainer.notesTooltip"),
        },
        {
          name: this.$t("maintenanceContainer.lastSeen"),
          value: new Date(existingContainer.location.timestamp).toLocaleString("uk"),
          tooltip: this.$t("maintenanceContainer.lastSeenTooltip"),
        },
      ];
    },
  },
  methods: {
    ...mapActions(["setScannedQrContainer"]),

    getImgUrl(arg) {
      let containerImage =
        "https://reenstorageaccount.blob.core.windows.net/containerimages/" +
        arg;
      return containerImage;
    },

    swapQrCode(qr) {
      if (qr === "") return;
      console.log(qr);

      if (qr === this.getScannedExistingContainer.qr_code) {
        this.wrongContainerFormat = true;
      } else {
        if (isValidQrCode(qr)) {
          this.setScannedQrContainer(qr);
          this.isShowingQrCamera = false;
          this.$router.push("swapqr");
          console.log(this.getScannedQrContainer);
        } else {
          this.wrongContainerFormat = true;
        }
      }
    },
    async saveContainer() {
      let existingContainer = this.getScannedExistingContainer;
      let existingState = this.getScannedExistingContainer.state;

      this.newState === ""
        ? (existingContainer.state = existingState)
        : (existingContainer.state = this.newState);

      try {
        await putContainer(existingContainer);
        this.isSaved = true;
      } catch (err) {
        console.error("der happn err:", err);
      }
    },
  },
};
</script>

<style scoped>
.scanBtn {
  text-align: center;
  border-radius: 5px;
  width: 224px;
  font-size: 1rem;
  color: #ffe893;
  border: solid #ffe893 2px;
  background-color: transparent;
  padding: 5px;
  margin-bottom: 10px;
}
.containerImg {
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  width: 224px;
  margin: auto;
  flex-direction: column;
}
.saveGroup {
  display: flex;
  justify-content: space-between;
  width: 224px;
  margin: auto;
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding-bottom: 20px;
}
.savBtn {
  text-align: center;
  border-radius: 5px;
  font-size: 1rem;
  color: #ffe893;
  border: solid #ffe893 2px;
  background-color: transparent;
}
.successText {
  display: flex;
  margin: auto;
  color: #65bf95;
}
</style>
