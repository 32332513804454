import axios from "axios";

export async function getVehicleInformation(regNo) {
    // let endpoint = "https://test-remoteregno.kjoretoydata.no/RemoteRegNoInformation/RegNoSearch?format=json";
    let endpoint = "https://remoteregno.kjoretoydata.no/RemoteRegNoInformation/RegNoSearch?format=json";

    let payload = {
        searchString: regNo,
        vehicleGroup: 101,
        user: {
            username: "remote_reen",
            password: "5a169518cd4cd9b7dacdbeae9e2fc5e1",
            ipAddress: ""
        }
    };

    return await axios.post(endpoint, payload).then(response => {
        if (response.data.success === true) {
            return response.data.data;
        } else if (response.data.success === false) {
            return response.data;
        }
    }).catch(error => {
        console.log("KUNNE IKKE HENTE KJØRETØYSINFORMASJON FRA OFV!!", error);
    });
}