<template>
  <div class="detailCard" v-if="error">
    <div class="container">
      <div class="topline">
        <h4>{{ $t("locationError.title") }}</h4>
      </div>
      <b-row>
        <b-col>
          <div>
            {{ $t("locationError.desc") }}
          </div>
          <Cancel />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      location: null,
      error: null,
      errorStr: null,
    };
  },
  created() {
    //do we support geolocation
    if (!("geolocation" in navigator)) {
      this.errorStr = "Geolocation is not available.";
      return;
    }
    // get position
    navigator.geolocation.watchPosition(
      (pos) => {
        this.location = pos;
        this.emitLocation();
      },
      () => {
        this.error = true;
      }, {enableHighAccuracy: true}
    );
  },
  methods: {
    emitLocation() {
      this.$emit("submit", this.location);
    },
  },
};
</script>

<style scoped>
.detailCard {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}
.container {
  background-color: #190833;
  border: 2px solid #ffc83a;
  border-radius: 20px;
  position: absolute;
  font-size: 1.2rem;
  z-index: 999;
  overflow: auto;
  width: 80%;
  height: 70%;
  top: 10%;
}
.topline {
  font-size: 1em;
  color: #dc3545;
  padding: 5px;
}
</style>
