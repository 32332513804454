import {
    getContainerTypes,
    getMountTypes,
    getRegions,
    getOrganizations,
    getOrganization,
    getStates,
    getUseTypes,
    getManufacturers,
    getBoxes,
    getSwapCauses,
    getVehicles
} from "../../services/reenapi";

import { sortAlphabetically } from "../../utils/utils";

const state = {
    containerTypes: [],
    mountTypes: [],
    constructionTypes: [],
    useTypes: [],
    condition: [],
    container: [],
    organizations: [],
    regions: [],
    manufacturers: [],
    boxes: [],
    swapCauses: [],
    vehicles: [],

    // Organisasjon og region som brukeren valgte på startskjermen.
    chosenOrganization: null,
    chosenRegion: null,

    // Organisasjon og region som REEN velger til esker/boxes
    chosenBoxOrganization: null,
    chosenBoxRegion: null,
};

const getters = {
    getContainerTypes: (state) => state.containerTypes,
    getMountTypes: (state) => state.mountTypes,
    getUseTypes: (state) => state.useTypes,
    getCondition: (state) => state.condition,
    getContainer: (state => state.container),
    getOrganizations: (state => state.organizations),
    getRegions: (state => state.regions),
    getManufacturers: (state => state.manufacturers),
    getBoxes: (state => state.boxes),
    getSwapCauses: (state => state.swapCauses),
    getVehicles: (state => state.vehicles),

    // Organisasjon og region som brukeren valgte på startskjermen.
    getChosenOrganization: state => state.chosenOrganization,
    getChosenRegion: state => state.chosenRegion,

    // Organisasjon og region som REEN velger til esker/boxes
    getChosenBoxOrganization: state => state.chosenBoxOrganization,
    getChosenBoxRegion: state => state.chosenBoxRegion,
};

const mutations = {
    mutateContainerTypes: (state, containerTypes) => (state.containerTypes = containerTypes),
    mutateMountTypes: (state, mountTypes) => (state.mountTypes = mountTypes),
    mutateConstructionTypes: (state, constructionTypes) => (state.constructionTypes = constructionTypes),
    mutateUseTypes: (state, useTypes) => (state.useTypes = useTypes),
    mutateCondition: (state, condition) => (state.condition = condition),
    addContainerType: (state, container) => (state.containerTypes.push(container)),
    mutateOrganizations: (state, organizations) => (state.organizations = organizations),
    mutateRegions: (state, regions) => (state.regions = regions),
    mutateManufacturers: (state, manufacturers) => (state.manufacturers = manufacturers),
    mutateBoxes: (state, boxes) => (state.boxes = boxes),
    mutateSwapCauses: (state, swapCauses) => (state.swapCauses = swapCauses),
    mutateVehicles: (state, vehicles) => (state.vehicles = vehicles),

    // Organisasjon og region som brukeren valgte på startskjermen.
    mutateChosenOrganization: (state, chosenOrganization) => state.chosenOrganization = chosenOrganization,
    mutateChosenRegion: (state, chosenRegion) => state.chosenRegion = chosenRegion,

    // Organisasjon og region som REEN velger til esker/boxes
    mutateChosenBoxOrganization: (state, chosenBoxOrganization) => state.chosenBoxOrganization = chosenBoxOrganization,
    mutateChosenBoxRegion: (state, chosenBoxRegion) => state.chosenBoxRegion = chosenBoxRegion,
};

const actions = {
    async setContainerTypes({ commit }) {
        let response = await getContainerTypes();

        let containerTypes = [];
        // Converts the data to the format used in bootstrap select
        for (let item of response.data) {
            const toBeFiltered = [
                "C-08LC",
                "C-08LCL",
                "C-10LC",
                "C-10LCL",
            ];

            if (toBeFiltered.includes(item.name)) {
                continue;
            }

            containerTypes.push({
                text: item.name,
                value: item,
            });
        }

        commit("mutateContainerTypes", sortAlphabetically(containerTypes));
    },

    async addContainerType({ commit }, containerType) {
        commit("addContainerType", containerType);
    },

    async setMountTypes({ commit }) {
        let axiosResponse = await getMountTypes();

        let response = [];
        let name = "";

        for (let item of axiosResponse.data) {
            // Filtrere ut disse som ikke brukes.
            if (item.value === 'None' ||
                item.value === 'Flatbed' ||
                item.value === 'Compactor') {
                continue;
            }

            // Oversette til norsk, men bare der det trengs.
            name = item.value;
            switch (item.value) {
                case "Hook":
                    name = "Krok";
                    break;
                case "Winch":
                    name = "Vinsj";
                    break;
            }

            response.push({
                text: name,
                value: item.value
            });
        }
        response.shift();

        commit("mutateMountTypes", sortAlphabetically(response));
    },

    async setUseTypes({ commit }) {
        let axiosResponse = await getUseTypes();

        let response = [];
        let name = "";

        for (let item of axiosResponse.data) {

            item.value === "EnvironmentallySafe" ? name = "Miljøvennlig" : "";
            item.value === "FoodWaste" ? name = "Matavfall" : "";
            item.value === "Glass" ? name = "Glass" : "";
            item.value === "Metal" ? name = "Metall" : "";
            item.value === "Plastic" ? name = "Plast" : "";
            item.value === "RecycledPaper" ? name = "Resirkulert papir" : "";
            item.value === "ResidualWaste" ? name = "Restavfall" : "";
            item.value === "Soil" ? name = "Jord" : "";
            item.value === "Steel" ? name = "Stål" : "";
            item.value === "Wood" ? name = "Trevirke" : "";

            let returnObj = {
                text: name,
                value: item.value
            };
            response.push(returnObj);
        }
        response.shift();

        response = sortAlphabetically(response);

        commit("mutateUseTypes", response)
    },

    async setCondition({ commit }) {
        let axiosResponse = await getStates();

        let states = [];
        let name = "";

        for (let item of axiosResponse.data.condition) {

            item.value === "A" ? name = "Pen" : "";
            item.value === "B" ? name = "Stygg" : "";
            item.value === "C" ? name = "Skadet" : "";
            item.value === "D" ? name = "Skrotet" : "";

            states.push({
                text: `${item.value} - ${name}`,
                value: item.value,
            });
        }
        commit("mutateCondition", states);
    },

    async setManufacturers({ commit }) {
        let axiosResponse = await getManufacturers();

        let response = []
        for (let item of axiosResponse.data) {
            if (item.discriminator === "Container") {
                let returnObj = {
                    ...item,
                    text: item.name,
                    value: item.id,
                };
                response.push(returnObj);
            }
        }

        response = sortAlphabetically(response);

        commit("mutateManufacturers", response)
    },

    async setOrganizations({ commit }) {
        let response = await getOrganizations();

        let organizations = [];

        response.data.forEach(organization => {
            organizations.push({
                ...organization,
                text: organization.name,
                value: organization.id,
            });
        });
        organizations = sortAlphabetically(organizations);
        commit("mutateOrganizations", organizations);
    },

    async setRegions({ commit }, organizationId) {
        if (!organizationId) {
            commit("mutateRegions", []);
            return;
        }

        const response = await getRegions(organizationId);

        let regions = response.data.map((region) => {
            return {
                ...region,
                text: region.name,
                value: region.id,
            };
        });

        regions = sortAlphabetically(regions);

        commit("mutateRegions", regions);
    },

    async setChosenOrganization({ commit, dispatch }, organizationId) {

        const response = await getOrganization(organizationId);

        if (response.status === 404) {
            return;
        }

        const organization = {
            ...response.data,
            text: response.data.name,
            value: response.data.id,
        }

        commit("mutateChosenOrganization", organization);
        dispatch("setRegions", organization.id);
    },

    setChosenRegion({ commit, getters }, regionId) {
        if (!regionId) return;

        const region = getters.getRegions.filter(region => {
            return region.id === regionId;
        })[0];

        commit("mutateChosenRegion", region);
    },

    async setBoxes({ commit }) {
        let axiosResponse = await getBoxes();

        let response = []
        for (let item of axiosResponse.data) {
            let returnObj = {
                ...item
            };
            response.push(returnObj);
        }
        commit("mutateBoxes", response)
    },

    async setSwapCauses({ commit }) {
        let axiosResponse = await getSwapCauses();

        let response = []
        let name = ""

        for (let item of axiosResponse.data) {

            if (item.value === 'Restored') continue;

            item.value === "BatteryLifetime" ? name = "Dødt batteri" : "";
            item.value === "LostUnit" ? name = "Enhet borte" : "";
            item.value === "MechanicalDamage" ? name = "Skade på enhet" : "";
            item.value === "Other" ? name = "Annet" : "";

            let returnObj = {
                text: name,
                value: item.value
            };
            response.push(returnObj);
        }
        response.shift();
        commit("mutateSwapCauses", sortAlphabetically(response));
    },

    // organisasjon for esker/boxes
    async setChosenBoxOrganization({ commit, dispatch }, organizationId) {
        const response = await getOrganization(organizationId);

        const organization = {
            ...response.data,
            text: response.data.name,
            value: response.data.id,
        }

        commit("mutateChosenBoxOrganization", organization);
        dispatch("setRegions", organization.id);
    },

    setChosenBoxRegion({ commit, getters }, regionId) {
        const region = getters.getRegions.filter(region => {
            return region.id === regionId;
        })[0];

        commit("mutateChosenBoxRegion", region);
    },

    async setVehiclesByRegion({ commit }, region_id) {
        let response = await getVehicles({ region: region_id });

        let newArray = []

        for (let item of response.data) {
            let obj = {
                text: item.license_plate.number,
                value: item.id
            }
            newArray.push(obj);
        }

        console.log(newArray);
        newArray = sortAlphabetically(newArray);

        commit("mutateVehicles", newArray)
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
