<template>
  <!-- Cancels the whole registration process -->
  <button class="submitButton cancelButton" @click="cancel">
    {{ $t('cancelButton') }}
  </button>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "CancelButton",
  props: {
    label: {
      type: String,
      default: "Cancel",
    },
    clicked: {
      type: Function,
      default: function(){},
    }
  },
  methods: {
    ...mapActions([
      "setNewContainer",
      "setScannedExistingContainer",
      "setScannedExistingUnit",
    ]),

    cancel() {
      this.clicked();
      this.setNewContainer({});
      this.setScannedExistingContainer({});
      this.setScannedExistingUnit({});
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.submitButton {
  position: relative;
  text-align: center;
  align-items: center;
  border-radius: 25px;
  width: 150px;
  padding: 10px;
  margin: 30px;
  font-size: 1.1rem;
  color: #ffe893;
  border: solid #ffe893 3px;
  background-color: transparent;
}

.cancelButton {
  border-color: #ff6a6a;
  color: #ff6a6a;
}
</style>