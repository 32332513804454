<template>
  <div class="background">
    <b-container>
      <b-row>
      <b-col>
        <div class="checkFinished">
          <br><br><br>
          <b-icon-check-circle
            font-scale="5"
            style="color: #ffe893"
          ></b-icon-check-circle>
          <br /><br />
          <span>{{$t('swapQrSuccess.info')}}</span>
        </div>
      </b-col>
      </b-row>

      <b-row>
        <b-col>
          <Button :label="$t('swapQrSuccess.back')" @clicked="backToMaintenance" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  methods: {
    backToMaintenance() {
      this.$router.push('maintenance')
    }
  }
};
</script>

<style scoped>
.checkFinished {
  margin-top: 50px;
}
span {
  color: white;
  font-size: 1.3rem;
}
.submitButton {
  position: relative;
  border-radius: 25px;
  width: 150px;
  padding: 10px;
  margin-top: 30px;
  font-size: 1.1rem;
  color: #ffe893;
  border: solid #ffe893 3px;
  background-color: transparent;
}
</style>
