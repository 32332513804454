import {
    getInstance
} from "@/auth";

const state = {
    token: null,
};

const getters = {
    token(state) {
        return state.token;
    },
};

const mutations = {
    mutateToken(state, token) {
        state.token = token;
    },
};

const actions = {
    setToken({
        commit
    }) {
        return new Promise((resolve, reject) => {
            const instance = getInstance();
            instance.$watch("loading", loading => {
                if (loading === false && instance.isAuthenticated) {
                    instance
                        .getTokenSilently()
                        .then(authToken => {
                            commit("mutateToken", authToken);
                            resolve(authToken);
                        })
                        .catch(error => {
                            reject(error);
                        });
                }
            });
        });
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};