import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { domain, clientId, audience } from "../auth_config.json";

import { Auth0Plugin } from "./auth/index.js";

import axios from "axios";

import VueQrcodeReader from "vue-qrcode-reader";

//Validation for RegisterContainer
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import { ValidationObserver } from 'vee-validate'
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// BOOTSTRAP
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(VueQrcodeReader);

Vue.use(Auth0Plugin, {
  axios,
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl ?
        appState.targetUrl :
        window.location.pathname
    );
  }
});

// Globalt importerte komponenter.
import InputComponent from "./components/inputs/InputComponent";
import CancelButton from "./components/buttons/CancelButton";
import ForwardButton from "./components/buttons/ForwardButton";
import i18n from './i18n'

Vue.component('Input', InputComponent);
Vue.component('Cancel', CancelButton);
Vue.component('Button', ForwardButton);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
